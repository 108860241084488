.user-experince-expertise-banner{
    background: url(../../../assets/user-experince-banner.png);
    background-position: 80% 60px;
    background-repeat: no-repeat;
    background-size: 35%;     
}
.clr-text-white{
    color: var(--prosouls-white);
    font-family: 'Avenir';
}
.user-experince-expertise-first-module {
    padding: 15% 0 10% 0;
    width: 35%;
    text-align: left;
    /* margin: 0 0 0 12.1%; */
}
.user-experince-expertise-first-module-btn-parent{
    margin: 20px 0;
}
.user-experince-expertise-first-module-btn{
    transition: all ease-in 0.1s;
    border: 2px solid var(--prosouls-blue);
    color: var(--prosouls-blue);
    text-transform: uppercase;
    padding: 10px 10px 8px 10px;
    width: max-content;
    text-decoration: none;
    font-family: 'Avenir';
    display: flex;
    align-items: center;
}
.user-experince-expertise-first-module-btn-icon{
    margin: 0 15px 0 5px;
}
.user-experince-expertise-first-module-btn:hover {
    color: var(--prosouls-blue) ;
    font-family: 'Avenir';
}
.user-experince-expertise-first-module-btn:hover .user-experince-expertise-first-module-btn-icon{
    margin: 0 5px 0 15px;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}
.user-experince-expertise-second-module-flex{
    display: flex;
    margin: 5% 0;
    width: 100%;
    overflow: hidden;
}
.user-experince-expertise-second-module-column.one{
    /* display: table-cell; */
  /* padding: 16px; */
    width: 40%;
}
.user-experince-expertise-second-module-parent .container{
    max-width: 1920px;
    padding: 0;
}
.user-experince-expertise-second-module-column.solid-bg{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    display: flex;
    align-items: center;
    width: 65%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.user-experince-stroke-font{
    font-size: 140px;
    font-family: 'Avenir';
    line-height: 0.9;
    margin-left: 0px;
    text-transform: uppercase;
    text-align: start;
    letter-spacing: 25px;
    color: var(--prosouls-white);
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
}
.user-experince-expertise-number-flex{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    column-gap: 10%;
    align-items: center;
}
.user-experince-expertise-number-column{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    padding: 20px 10px;
    width: 35%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.user-experince-expertise-number-column h1{
    font-size: 50px;
    text-align: center;
    font-family: 'Avenir';
    font-weight: 900;
    margin: 0;
}
.user-experince-expertise-number-column p{
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-family: 'Avenir';
}
.user-experience-solid-bg-ul{
    columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  /* list-style: none; */

}
.user-experience-solid-bg-list-items{
    text-align: start;
    color: var(--prosouls-white);
}
.user-experience-solid-bg-list-items p{
    font-size: 20px;
}
.user-experince-expertise-solid-bg-content{
    width: 70%;
    margin: 0 auto 0 60px;
}
.user-experince-expertise-solid-bg-content h1 , h2, h3{
    text-align: start;
    margin-bottom: 20px;
}
.user-experince-expertise-third-module-parent .container {
    display: flex;
    column-gap: 150px;
    align-items: flex-start;

}
.user-experince-expertise-third-module-details{
    margin: 40px 0px;
    padding: 20px;
    text-align: start;
        display: flex;
    column-gap: 25px;   
    align-items: flex-start;
    /* background: linear-gradient(139deg , transparent, var(--prosouls-blue)36); */
}
.user-experince-expertise-third-module-parent .sub-title{
    font-size: 50px;
}
.user-experince-expertise-third-module-parent .main-title{
    font-size: 70px;
}
.clr-text-blue{
    color: var(--prosouls-blue);
    font-weight: 400;
    font-family: 'Avenir';
}
.user-experince-expertise-third-module-parent{
    background: url(../../../assets/user-experincebuiding.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;
    padding: 100px 0;
}
.user-experince-expertise-third-module {
    /* padding: 10% 0 10% 0;     */
    text-align: start;
    width: 50%;
    margin-bottom: 100px;
}
.user-experince-expertise-third-module-icon{
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
.user-experince-expertise-fourth-bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-experince-expertise-underline{
    height: 5px;
    width: 20%;
    background: var(--prosouls-blue);
    border-radius: 10px;
}
.user-experince-expertise-sixth-module-parent{
    margin: 100px 0;
}
.user-experince-expertise-sixth-module-parent h1{
    width: 60%;
    text-align: start;
}
.user-experince-expertise-get-qoute , .user-experince-expertise-get-qoute:hover{
    padding: 10px 25px;
    background-color: var(--prosouls-blue);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--prosouls-white);
    text-decoration: none;
}
.get-qoute-parent-user-experince-expertise{
    text-align: start;
    margin: 50px 0;
}
.user-experience-table-section{
    width: 100%;
}
.user-experience-table{
    width: 100%;
}
.user-experince-table-head{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    text-align: center;
    padding: 20px;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.user-experince-table-head h1{
    text-align: center;
    font-size: 60px;
    margin:0;
    font-weight: 700;
}
.user-experience-table tbody tr td{
    width: 50%;
    border: 2px solid white ;
    padding: 20px 40px ;
}
.user-experience-table tbody tr td p{
    text-align: start;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.user-experience-table tbody tr td h3{
    text-align: start;
    font-size: 30px;
    color: var(--prosouls-white);
    text-transform: uppercase;
}
.user-experience-table tbody tr td h4{
    text-align: start;
    font-size: 25px;
    color: var(--prosouls-white);
}
.clr-txt-blue{
    color: var(--prosouls-blue);
}
.user-experince-expertise-fourth-module-parent{
    margin: 5% 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {

    .user-experince-expertise-banner {
        background-size: 90%;
        background-position: center 100px;
    }
    .user-experince-expertise-first-module {
        padding: 110% 0 15% 0;
        width: 100%;
        text-align: center;
    }
    .user-experince-expertise-second-module-flex {
        display: grid;
    }
    .user-experince-expertise-second-module-column.one {
        width: 100%;
    }
    .user-experince-stroke-font {
        font-size: 40px;
        text-align: center;
        letter-spacing: 3px;
    }
    .user-experince-expertise-number-flex {
        display: grid;
        margin-top: 50px;
    }
    .user-experince-expertise-number-column {
        padding: 20px;
        width: 100%;
        margin-bottom: 20px;
    }
    .user-experince-expertise-second-module-column.solid-bg {
        width: 100%;
    }
    .user-experince-expertise-solid-bg-content {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }
    .user-experince-expertise-third-module-parent .sub-title {
        font-size: 25px;
    }
    .user-experince-expertise-third-module-parent .main-title {
        font-size: 40px;
    }
    .user-experience-solid-bg-ul {
        columns: 1;
    }
    .user-experince-expertise-third-module-parent {
        background-position: center bottom;
        padding: 80px 0;
    }
    .user-experince-table-head h1 {
        font-size: 40px;
    }
    .user-experience-table tr{
        display: grid;
    }
    .user-experience-table tbody tr td {
        width: 100%;
        border: 2px solid white;
        padding: 20px;
    }
    .user-experience-table tbody tr:first-child td {
        border-bottom: none;
    }
    .user-experience-table tbody tr:last-child td:not(:last-child) {
        border-bottom: none;
    }
.user-experince-expertise-third-module-parent .container {
    display: contents;
}
.user-experince-expertise-third-module{
    width: 87%;
    margin: 0 auto;
}
.user-experince-expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
}
.user-experince-expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
}
.user-experince-expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg , transparent, var(--prosouls-blue)36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
}
.user-experince-expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
}