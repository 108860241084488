.cstm-our-approach-container{
  margin: 0 10%;
}
.our-approach-heading h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 70px;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 70px;
  grid-gap: 30px;
  align-items: center;
  align-content: center;
}

.our-approach-heading h1:after, .our-approach-heading h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
  background-color:#f8f8f8;
}
.our-approach-content-para{
  width: 90%;
  margin: 20px auto;
}
.our-approach-bullet-inner-icon{
  border: 1px solid var(--prosouls-blue);
  border-radius: 100px;
  width: max-content;
  padding: 0px;
  width: 70px;
  z-index: 9;
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
  background: #021928;
}
.our-approach-bullet-inner-icon img{
  width: 60%;
  height: auto;
} 
.our-approach-bullet-points{
  background: url(../../assets/our-approach/our-approach.webp);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 88% center;
  padding: 60px 0;
}
.our-approach-bullet-parent{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
}
.our-approach-bullet-inner-text{
  min-width: 240px;
  margin-left: -22px;
  border: 4px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 10px;
  padding: 15px 15px 15px 40px;
  border-image-source: linear-gradient(to right,  transparent 10%, var(--prosouls-blue) 100%);
  /* border: 4px solid;
  border-image: conic-gradient( from var(--angle), var(--c2), var(--prosouls-blue) 0.1turn, var(--prosouls-blue) 0.15turn, var(--c2) 0.25turn ) 30;
  animation: borderRotate var(--d) linear infinite forwards; */
}
.our-approach-bullet-inner-text p{
  font-family: 'Avenir';
  font-weight: 100;
  margin: 0;
}
.our-approach-bullet-ul{
  padding: 0;
  width: 60%;
  /* column-count: 2; */
  text-align: initial;
}
.our-approach-bullet-li{
  margin: 20px;
  list-style: none;
  display: inline-block;

}

@media screen and (min-width: 901px) {
}
@media screen and (max-width: 900px) {
  .our-approach-heading h1 {
    font-size: 25px;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 40px;
    grid-gap: 15px;
  } 
  .our-approach-bullet-points {
    background-size: 100%;
    background-position: center 25px;
    padding: 70% 0 0 0;
}
.our-approach-content-para{
  display: none;
}
.our-approach-bullet-inner-text {
  min-width: 75%;
  margin-left: 0 ;
  border: 4px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
  border-image-source: linear-gradient(to right, transparent, var(--prosouls-blue));
}
.our-approach-bullet-ul {
  padding: 0;
  width: 100%;
  /* column-count: 2; */
  text-align: initial;
}
.our-approach-bullet-li {
  margin: 20px;
  list-style: none;
  display: grid;
}
/* .our-approach-bullet-inner-icon{
  display: none;
} */
.our-approach-bullet-li {
  margin: 20px 0 ;
  list-style: none;
}
.cstm-our-approach-container {
  margin: 10%;
}
.our-approach-bullet-inner-icon {
  width: 60px;
  height: 60px;
}
}