/* .nav-link{
  transition: all ease-in-out 0.3s;
  border: 1px solid transparent;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link:hover , .navbar-nav .show>.nav-link {
  color: var(--prosouls-blue) !important;
  box-shadow: 0px 5px 10px var(--prosouls-blue);
  border: 1px solid var(--prosouls-blue);
  border-radius: 10px ;
} */
.nav-brand-logo{
  width: 180px;
  height: auto;
}
.dropdown-menu{
    min-width: max-content !important;
    overflow: hidden;
    background: #001a27cc;
}
.dropdown-item{
    color: var(--prosouls-white);
    padding: 5px 15px;
    transition: all ease-in-out 0.3s;
}
.dropdown-item:hover{
    background-color: var(--prosouls-blue);
    color: var(--prosouls-black);
    /* padding: 10px 30px; */
}
.cstm-mng-col{
    column-gap: 30px !important;
    justify-content: center !important;    
}
@keyframes slideIn {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .nav-dropdown-item {
    animation: slideIn 0.5s ease-in-out forwards;
    opacity: 0;
  }
  
  .nav-dropdown-item:nth-child(1) {
    animation-delay: 0s;
  }
  
  .nav-dropdown-item:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .nav-dropdown-item:nth-child(3) {
    animation-delay: 0.2s;
  }
  .nav-dropdown-item:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .nav-dropdown-item:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  .nav-dropdown-item:nth-child(6) {
    animation-delay: 0.5s;
  }
  .nav-dropdown-item:nth-child(7) {
    animation-delay: 0.6s;
  }
  
  .nav-dropdown-item:nth-child(8) {
    animation-delay: 0.7s;
  }
  
  .nav-dropdown-item:nth-child(9) {
    animation-delay: 0.8s;
  }
  .nav-dropdown-item:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  .nav-dropdown-item:nth-child(11) {
    animation-delay: 1s;
  }
  .dropdown-menu{
    max-height: 350px;
    overflow-y: auto;
  }
  .dropdown-menu::-webkit-scrollbar {
    background-color:#021928;
    width: 5px;
    /* border-radius: 10px; */
  }
  
  .dropdown-menu::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  }
  
  .dropdown-menu::-webkit-scrollbar-thumb {
    /* background-image: -webkit-gradient(linear, left bottom, left top, color-stop(.5, #a520ca), color-stop(1, #2681cc)); */
    background-image: linear-gradient(to bottom, #29ABE2 , #00FFFF);
    border-radius: 10px;
  }
  .navbar-toggler:focus{
    /* border: none; */
    box-shadow: none;
    outline :none;
  }
  @media screen and (max-width:991.98px) {
    .cstm-social-icon-header{
      display: flex;
      flex-direction: row;
      justify-content: center !important;
    }
    .cstm-social-icon-header a{
      width: 10%;
    }
    .dropdown-menu{
      background: transparent;
  }
  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
  }