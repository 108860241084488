
@media screen and (max-width: 900px ) {
  .clientheading {
    text-align: start;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 30px;
    font-family: Avenir;
    color: var(--prosouls-white);
  }
  
  .headingcontainer {
    width: 50%;
    margin-left: 20%;
  }
  
  .client {
    margin-top: 35%;
  }
}

@media screen and (min-width: 901px) {
  .clientheading {
    text-align: start;
    justify-content: flex-start;
    align-items: flex-start;
    font-size:40px;
    font-family: Avenir;
    color: var(--prosouls-white);
}

.headingcontainer {
    width: 30%;
    margin-left: 20%;
    
}

.client {
    margin-top: 15%;
}

}

