.it-infrastructure-expertise-banner{
    background: url(../../../assets/it-infrastructure/IT-Infrastructur.png);
    background-position: 80% 60px;
    background-repeat: no-repeat;
    background-size: 50%;     
}
.clr-text-white{
    color: var(--prosouls-white);
    font-family: 'Avenir';
}
.it-infrastructure-expertise-first-module {
    padding: 15% 0 10% 0;
    width: 35%;
    text-align: left;
    /* margin: 0 0 0 12.1%; */
}
.it-infrastructure-expertise-first-module-btn-parent{
    margin: 20px 0;
}
.it-infrastructure-expertise-first-module-btn{
    transition: all ease-in 0.1s;
    border: 2px solid var(--prosouls-blue);
    color: var(--prosouls-blue);
    text-transform: uppercase;
    padding: 10px 10px 8px 10px;
    width: max-content;
    text-decoration: none;
    font-family: 'Avenir';
    display: flex;
    align-items: center;
}
.it-infrastructure-expertise-first-module-btn-icon{
    margin: 0 15px 0 5px;
}
.it-infrastructure-expertise-first-module-btn:hover {
    color: var(--prosouls-blue) ;
    font-family: 'Avenir';
}
.it-infrastructure-expertise-first-module-btn:hover .it-infrastructure-expertise-first-module-btn-icon{
    margin: 0 5px 0 15px;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}
.it-infrastructure-expertise-second-module-flex{
    display: flex;
    margin: 5% 0;
    width: 100%;
    overflow: hidden;
}
.it-infrastructure-expertise-second-module-column.one{
    /* display: table-cell; */
  /* padding: 16px; */
    width: 40%;
}
.it-infrastructure-expertise-second-module-parent .container{
    max-width: 1920px;
    padding: 0;
}
.it-infrastructure-expertise-second-module-column.solid-bg{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    display: flex;
    align-items: center;
    width: 65%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.it-infrastructure-stroke-font{
    font-size: 140px;
    font-family: 'Avenir';
    text-transform: uppercase;
    line-height: 0.9;
    margin-left: 0px;
    text-align: start;
    letter-spacing: 25px;
    color: var(--prosouls-white);
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
}
.it-infrastructure-expertise-number-flex{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    column-gap: 10%;
    align-items: center;
}
.it-infrastructure-expertise-number-column{
    background-image: linear-gradient(to right, #29ABE2 , #00FFFF);
    padding: 20px 50px;
    width: 35%;
}
.it-infrastructure-expertise-number-column h1{
    font-size: 50px;
    text-align: center;
    font-family: 'Avenir';
    font-weight: 900;
    margin: 0;
}
.it-infrastructure-expertise-number-column p{
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-family: 'Avenir';
}
.it-infrastructure-solid-bg-ul{
    columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  /* list-style: none; */

}
.it-infrastructure-solid-bg-list-items{
    text-align: start;
    color: var(--prosouls-white);
}
.it-infrastructure-solid-bg-list-items p{
    font-size: 20px;
}
.it-infrastructure-expertise-solid-bg-content{
    width: 70%;
    margin: 0 auto 0 60px;
}
.it-infrastructure-expertise-solid-bg-content h1 , h2, h3{
    text-align: start;
    margin-bottom: 20px;
}
.it-infrastructure-expertise-third-module-parent .container {
    display: flex;
    column-gap: 150px;
    align-items: flex-start;

}
.it-infrastructure-expertise-third-module-details{
    margin: 40px 0px;
    padding: 20px;
    text-align: start;
        display: flex;
    column-gap: 25px;   
    align-items: flex-start;
    /* background: linear-gradient(139deg , transparent, var(--prosouls-blue)36); */
}
.it-infrastructure-expertise-third-module-parent .sub-title{
    font-size: 50px;
}
.it-infrastructure-expertise-third-module-parent .main-title{
    font-size: 70px;
}
.clr-text-blue{
    color: var(--prosouls-blue);
    font-weight: 400;
    font-family: 'Avenir';
}
/* .it-infrastructure-expertise-third-module-parent{
    background: url(../../../assets/it-infrastructurebuiding.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;
    padding: 100px 0;
} */
.it-infrastructure-expertise-third-module {
    /* padding: 10% 0 10% 0;     */
    text-align: start;
    width: 50%;
    margin-bottom: 100px;
}
.it-infrastructure-expertise-third-module-icon{
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
.it-infrastructure-expertise-fourth-bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.it-infrastructure-expertise-underline{
    height: 5px;
    width: 20%;
    background: var(--prosouls-blue);
    border-radius: 10px;
}
.it-infrastructure-expertise-sixth-module-parent{
    margin: 100px 0;
}
.it-infrastructure-expertise-sixth-module-parent h1{
    width: 60%;
    text-align: start;
}
.it-infrastructure-expertise-get-qoute , .it-infrastructure-expertise-get-qoute:hover{
    padding: 10px 25px;
    background-color: var(--prosouls-blue);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--prosouls-white);
    text-decoration: none;
}
.get-qoute-parent-it-infrastructure-expertise{
    text-align: start;
    margin: 50px 0;
}
.it-infrastructure-table-section{
    width: 100%;
}
.it-infrastructure-table{
    width: 100%;
}
.it-infrastructure-table-head{
    background-image: linear-gradient(to right, #29ABE2 , #00FFFF);
    text-align: center;
    padding: 20px;
}
.it-infrastructure-table-head h1{
    text-align: center;
    font-size: 60px;
    margin:0;
    font-weight: 700;
}
.it-infrastructure-table tbody tr td{
    width: 33%;
    border: 2px solid white ;
    padding: 20px 40px ;
    vertical-align: baseline;
}
.it-infrastructure-table tbody tr td p{
    text-align: start;
    /* margin: 0; */
    /* font-size: 20px; */
    font-weight: 500;
}
.it-infrastructure-table tbody tr td h3{
    text-align: start;
    font-size: 30px;
    color: var(--prosouls-white);
    text-transform: uppercase;
}
.it-infrastructure-table tbody tr td h4{
    text-align: start;
    font-size: 25px;
    color: var(--prosouls-white);
}
.clr-txt-blue{
    color: var(--prosouls-blue);
}
.it-infrastructure-expertise-fourth-module-parent{
    margin: 5% 0;
}
.it-infrastructure-content{
    font-size: 25px;
}
.it-infrastructure-table-img{
    
    object-fit: contain;
    width: 80%;
    height: auto;
    vertical-align: text-top;

}
.it-infrastructure-third-module-section {
    display: table;
    border-bottom: 2px solid white;
}
.it-infrastructure-third-module-section__item{
    width: 50%;
    display: table-cell;
}
.it-infrastructure-third-module-section__item.column-one{
    border-right: 2px solid white;
    padding: 20px 30px 40px 0px;
}
.it-infrastructure-third-module-section__item.column-two{
    padding: 20px 0px 40px 30px;
}
.it-infrastructure-fourth-module-section {
    display: table;
    /* border-bottom: 2px solid white; */
    /* margin: 2rem auto; */
}
.it-infrastructure-fourth-module-section__item{
    width: 50%;
    display: table-cell;
}
.it-infrastructure-fourth-module-section__item.column-one{
    /* border-right: 2px solid white; */
    padding: 20px 30px 40px 0px;
}
.it-infrastructure-fourth-module-section__item.column-two{
    padding: 20px 0px 40px 30px;
}
.it-infrastructure-fourth-inner-module-section {
    display: table;
    /* border-bottom: 2px solid white; */
    /* margin: 2rem auto; */
}
.it-infrastructure-fourth-inner-module-section__item{
    width: 50%;
    display: table-cell;
}
.it-infrastructure-fourth-inner-module-section__item.column-one{
    /* border-right: 2px solid white; */
    padding: 10px 20px 10px 0;
}
.it-infrastructure-fourth-inner-module-section__item.column-two{
    padding :10px 20px;
}

.it-support-eight h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 70px;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 70px;
    grid-gap: 30px;
    align-items: center;
    align-content: center;
  }
  
  .it-support-eight h1:after,.it-support-eight h1:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ccc;
    background-color:#f8f8f8;
  }
  .mng-line-height{
    line-height: 2;
  }
  .it-infrastructure-fifth-module-section {
    display: table;
    /* border-bottom: 2px solid white; */
}
.it-infrastructure-fifth-module-section__item{
    width: 50%;
    display: table-cell;
}
.it-infrastructure-fifth-module-section__item.column-one{
    border-right: 2px solid white;
    padding: 20px 30px 20px 0px;
}
.it-infrastructure-fifth-module-section__item.column-two{
    padding: 20px 0px 20px 30px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {

    .it-infrastructure-expertise-banner {
        background-size: 100%;
        background-position: center 115px;
    }
    .it-infrastructure-expertise-first-module {
    padding: 100% 0 15% 0;
    width: 100%;
    text-align: center;
}
.it-infrastructure-expertise-second-module-flex {
    display: grid;
}
.it-infrastructure-expertise-second-module-column.one {
    width: 100%;
}
.it-infrastructure-stroke-font {
    font-size: 40px;
    text-align: center;
    letter-spacing: 3px;
}
.it-infrastructure-expertise-second-module-column.solid-bg {
    width: 100%;
}
.it-infrastructure-expertise-solid-bg-content {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
}
.it-infrastructure-third-module-section {
    display: grid;
    border-bottom: none;
}
.it-infrastructure-third-module-section__item.column-one {
    border-right: none;
    padding: 20px;
}
.it-infrastructure-third-module-section__item {
    width: 100%;
}
.it-infrastructure-third-module-section__item.column-two {
    padding: 20px;
}
.it-infrastructure-fourth-module-section {
    display: grid;
}
.it-infrastructure-fourth-module-section__item {
    width: 100%;
}
.it-infrastructure-fourth-module-section__item.column-one {
    padding: 20px ;
    order: 2;
}
.it-infrastructure-fourth-inner-module-section {
    display:grid;
}
.it-infrastructure-fourth-inner-module-section__item {
    width: 100%;
    padding: 20px !important;
}
.it-support-eight h1 {
    font-size: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px;
    grid-gap: 15px;
  }
  .it-infrastructure-fifth-module-section {
    display: grid;
    /* border-bottom: 2px solid white; */
}
.it-infrastructure-fifth-module-section__item {
    width: 100%;
    padding: 20px !important;
    border: none !important;
}
.it-infrastructure-expertise-third-module-parent .container {
    display: contents;
}
.it-infrastructure-expertise-third-module{
    width: 87%;
    margin: 0 auto;
}
.it-infrastructure-expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
}
.it-infrastructure-expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
}
.it-infrastructure-expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg , transparent, var(--prosouls-blue)36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
}
.it-infrastructure-expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
}