h3.clr-txt,
h1.clr-txt {
  font-weight: 500;
  font-family: 'Avenir';
}

.carousel-item-custom {
  opacity: 0;
  transition: opacity 0.5s !important;
}

.carousel-item-custom.active {
  opacity: 1;
  transition: opacity 0.5s !important;
}

.text-container-parent {
  margin-left: 10%;
}

.clr-txt {
  color: var(--prosouls-white);
  text-align: start;
  font-family: 'Avenir';
}

.mob-text {
  margin-left: 10%;
  margin-top: 3%;
  position: relative;
}

.mob-text img {
  position: relative;
}

.mob-text .img-box-text {
  position: absolute;
  top: 25%;
  left: 0;
  color: var(--prosouls-white);
  font-family: 'Avenir';
  font-weight: 500;
  width: 240px;

}

.mob-text .img-box-text h3 {
  margin-bottom: 40px;
}

.mob-text .img-box-text p {
  font-size: 13px;
  font-family: 'Avenir';
}

.get-in-touch-btn,
.get-in-touch-btn:hover {
  background: #00aee3;
  padding: 15px 20px;
  border-radius: 10px;
  color: var(--prosouls-white);
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;

}

.get-qoute-parent {
  position: absolute;
  bottom: 57.5px;
  left: 350px;
}

.app-scnd-module,
.app-third-module {
  margin: 10% auto 0 auto;
}

.third-module-heading {
  font-size: 50px;
  font-weight: 500;
  font-family: 'Avenir';
  text-align: left;
  letter-spacing: 1px;
}

.scnd-module-heading {
  font-size: 50px;
  font-weight: 500;
  font-family: 'Avenir';
  text-align: center;
  letter-spacing: 1px;
}

.bg-blue-container {
  background: #00aee3;
  padding: 20px 0 20px 0;
}

.app-third-head-parent {
  margin-left: 12.5%;
}

@media screen and (min-width: 901px) {
  .heading {
    display: block;
    font-family: Avenir;
    color: var(--prosouls-white);
    text-align: start;
    font-size: 40px;
    /* margin-left: 24%; */

    /* margin-top: 20px; */
  }

  .carousel-control-next-icon {
    visibility: hidden;
  }

  .carousel-control-prev-icon {
    visibility: hidden;
  }

  .work {
    margin-top: 10%;
  }

  .textwork {
    text-align: justify;
    /* margin-top: 50px; */
    /* margin-left: 6%; */
    /* margin-right: 30%; */
    font-size: 16px;
    font-family: Avenir;
    color: var(--prosouls-white);
    /* margin-bottom: 10%; */
  }

  .colm {
    display: flex;
    /* margin-left: 80px; */
    width: 70%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    column-gap: 200px;
  }

  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }

  .heading1 {
    /* margin-left: 15%; */
    width: 30%;
    /* padding-top: 20px; */
  }

  .text1 {
    /* margin-right: 10%; */
    width: 50%;
    text-align: start;
    align-items: flex-start;
  }
  .text-container-parent{
    width: 30%;
  }
}

@media screen and (max-width: 900px) {
  .carousel-parent-container{
    background-position: right 100px !important;
    background-size: 165% !important;
    padding: 125% 0px 0 0 !important;
  }
  .w-50{
    width: 90%;
    margin: 0 auto 15px auto;
    text-align: center;
  }
  .scnd-module-heading , .third-module-heading {
    font-size: 25px;
}
.w-50 br{
  display: none;
}
  .app-third-head-parent{
    margin: 0;
  }
  .text-container-parent {
    margin-left:0;
}
  .heading {
    display: block;
    font-size: 25px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-top: 20px;
  }

  .carousel-control-next-icon {
    visibility: hidden;
  }

  .carousel-control-prev-icon {
    visibility: hidden;
  }

  .work {
    margin-top: 10%;
  }

  .textwork {
    text-align: center;
    margin-top: 25px;
    margin-left: 12%;
    margin-right: 12%;
    font-size: 14px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-bottom: 10%;
  }

  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }
}

.w-50 {
  margin-right: 2% !important;
  width: 95% !important;
}

.react-multi-carousel-list {
  margin: 5%;
}