@media screen and (min-width: 901px) {
  .heading {
    display: block;
    font-family: Avenir;
    color: var(--prosouls-white);
    text-align: start;
    font-size: 40px;
    /* margin-left: 24%; */

    /* margin-top: 20px; */
  }
  .services {
    margin:5% 10% !important;
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: 100px;
  }

  .services {
    text-align: justify;
    color: var(--prosouls-white);
    font-size: 16px;
    font-family: Avenir;
  
  }
.services-image{
  width: 90%;

}


  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }
 .carousel-cstm-inner-sec{
  width: 100%;
  height: 700px;
  display: flex;
  column-gap: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.carousel-cstm-inner-sec .carousel-cstm-inner-column-one{
  width: 42.5%;
}
.carousel-cstm-inner-sec .carousel-cstm-inner-column-two{
  width: 42.5%;
}
.services-image{
  width: 100%;
  margin: 0 auto;
}

}
.services-image .carousel-control-next-icon {
  visibility: visible !important;
}

.services-image .carousel-control-prev-icon {
  visibility: visible !important;
}
.services-image .carousel-control-next{
  justify-content: flex-end !important;
}
.services-image  .carousel-control-prev{
  justify-content: flex-start !important;
} 

@media screen and (max-width: 900px) {
  .heading {
    display: block;
    font-size: 25px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-top: 20px;
  }

  .carousel-control-next-icon {
    visibility: hidden;
  }

  .carousel-control-prev-icon {
    visibility: hidden;
  }

  .services {
    display:grid;
    margin-top: 10%;
    overflow: hidden;
  }

  .services {
    text-align: center;
    margin-top: 25px;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 14px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-bottom: 10%;
  }
  .cstm-service-ul{
    text-align: start;
  }

  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }
  .services-image{
  width: 100%;
  order: 1;
  }
  .colm{
    width: 100%;
    order: 2;
  }
}

.w-50 {
  margin-right: 50px !important;
  width: 80% !important;
}

.react-multi-carousel-list {
  margin: 5%;
}
/* .services-image{
  transform: translateX(-2%);
animation: floater 2s infinite;
transition: ease 0.5s;
}
@keyframes floater {
  0%{transform: translateX(-2%);transition: ease 0.5s;}
  50%{transform: translateX(2%);transition: ease 0.5s;}
} */