/* @media screen and (min-width: 901px) {
  }
  
  @media screen and (max-width: 900px) {
  } */
  .casestudy-banner{
    background: url(../../assets/case-studies/case-studies-banner.jpg);
    background-repeat: no-repeat;
    background-position: center 55%;
    background-size: 100%;
  }
  .casestudy-banner-container{
    min-height: 600px;
    display: flex;
    align-items: flex-end;
  }
  .casestudy-banner-content{
    padding-bottom: 60px;
    color: #fff;
    width: 80%;
  }
  .clr-txt{
    color: var(--prosouls-white);
    font-family: 'Avenir';
  }
  .casestudy-content-subtitle{
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 1rem;
  }
  .casestudy-content-title{
    margin-bottom:0;
  }
  :root {
    --color: var(--prosouls-white);
    --transition-time: 0.5s;
    --bg-filter-opcity: linear-gradient(145deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3));
  }
  
  .case-studies-cards-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    padding: 4rem;
    margin: 0 auto;
    width: max-content;
  }
  
  .case-studies-card {
    font-family: 'Avenir';
    --bg-filter-opacity: 0.5;
    height: 20em;
    width: 15em;
    font-size: 1.5em;
    color: var(--prosouls-white);
    border-radius: 1em;
    padding: 1em;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    /* box-shadow: 0 0 5em -1em black; */
    transition: all, var(--transition-time);
    position: relative;
    overflow: hidden;
    /* border: 10px solid #ccc; */
    text-decoration: none;
    justify-content: flex-end
  }
  
  .case-studies-card:hover {
    transform: rotate(0);
  }
  
  .case-studies-card h1 {
    margin: 0;
    font-family: 'Avenir';
    font-size: 1.2em;
    line-height: 1.2em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }
  
  .case-studies-card p {
    font-size: 0.60em;
    font-family: 'Avenir';
    margin-top: 0.5em;
    line-height: 2em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
  }
  

  
  .case-studies-card .date {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75em;
    padding: 1em;
    line-height: 1em;
    opacity: .8;
  }
  
  .case-studies-card:before, .case-studies-card:after {
    content: '';
    transform: scale(0);
    transform-origin: top left;
    border-radius: 50%;
    position: absolute;
    left: -50%;
    top: -50%;
    z-index: -5;
    transition: all, var(--transition-time);
    transition-timing-function: ease-in-out;
  }
  
  .case-studies-card:before {
    background-image: linear-gradient(to right, #29ABE2 , #00FFFF);
    width: 250%;
    height: 250%;
  }
  
  .case-studies-card:after {
    background: transparent;
    filter:blur(4px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(4px);
    -webkit-filter:blur(4px);
    width: 200%;
    height: 200%;
  }
  
  .case-studies-card:hover {
    color: var(--color);
  }
  
  .case-studies-card:hover:before, .case-studies-card:hover:after {
    transform: scale(1);
  }
  
  
  /* MEDIA QUERIES */
  @media screen and (max-width: 1285px) {
    .case-studies-cards-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (max-width: 900px) {
    .case-studies-cards-wrapper {
      grid-template-columns: 1fr;
    }
    .info {
      justify-content: center;
    }
    .casestudy-banner {
      /* background: url(/static/media/case-studies-banner.80bd452….jpg); */
      background-position: center 110px;
      background-repeat: no-repeat;
      background-size: contain;
      padding-top: 100px;
  }
  .casestudy-banner-container {
    align-items: flex-end;
    display: flex;
    min-height: 250px;
    /* padding: 100px 0 0 0; */
}
.casestudy-banner-content {
  color: #fff;
  padding-bottom: 20px;
  width: 90%;
  margin: 0 auto;
}
.casestudy-content-subtitle {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
  text-transform: uppercase;
  text-align: left !important;
}
.casestudy-content-title {
  margin-bottom: 0;
  font-size: 16px;
  text-align: left !important;
}
  }
  
  @media screen and (max-width: 500px) {
    .case-studies-cards-wrapper {
      padding: 4rem 2rem;
    }
    .case-studies-card {
      max-width: calc(100vw - 4rem);
    }
  }