@media screen and (max-width: 900px) {
    .approach1234 {
        font-size: 30px;
        word-spacing: 3vw;
        font-family: Avenir;
        color: var(--prosouls-white);
        margin-left: 12%;
        word-spacing: normal;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 900;
    }

    .careerpara {
        margin-top: 20px;
        font-size: 14px;
        font-family: Avenir;
        color: var(--prosouls-white);
        text-align: center;
        word-spacing: normal;
    }

    .hello123 {
        width: 90%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .container1234 {

        align-items: center;
        align-content: center;
        text-align: center;
        justify-content: center;
        background-color: "#021928";
        padding-top: 10%;

    }

    .img1234 {
        padding-top: 20%;
        width: 80%;
        position: relative;
    }

    .btn45 {
        /* width: 17vw; */
        cursor: pointer;
        text-align: start;
        font-size: 20px;
        color: white;

    }


    .approach12345 {
        margin-top: 100px;
        font-size: 30px;
        word-spacing: 1vw;
        font-family: Avenir;
        color: var(--prosouls-white);
        margin-left: 12%;
        word-spacing: normal;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .job {
        margin-top: 10px;
        font-size: 14px;
        font-family: Avenir;
        color: var(--prosouls-white);
        text-align: center;
        word-spacing: normal;
    }

    .jobopening {
        width: 80%;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 5%;
    }

    .accordion {
        --bs-accordion-bg: transparent;
        --bs-accordion-color: var(--prosouls-white);
        --bs-accordion-btn-color: var(--prosouls-white) --bs-accordion-active-color: transparent;
        --bs-accordion-active-bg: transparent;
    }

    .dropdowns {
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
        position: relative;
    }


    .headingdd {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin-top: 20px;
        max-width: 100%;
        font-size: 25px;
    }

    .textdd {
        font-size: 16px;
        letter-spacing: normal;
        word-spacing: normal;
        margin-top: 20px;
        text-align: start;
        justify-content: flex-start;
    }

    .jobtype {
        margin-top: 50px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        
        font-size: 20px;
        word-spacing: normal;
    }

    .jobtype1 {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        
        font-size: 20px;
        word-spacing: normal;
    }

    .email {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        font-size: 30px;
        
        margin-top: 50px;
    }

    .frontsales {
        margin-top: 30px;
        align-items: flex-start;
        text-align: start;
        
    }
    .career-second-module{
        margin: 100px auto 50px;

    }
    .career-cards-flex {
        margin: 7.5% 0;
        display: grid;
        border-spacing: 0px;
    }
    .career-cards-column {
        border: 1px solid white;
        position: relative;
        display: table-cell;
        width: 90%;
        padding: 30px;
        margin: 50px auto 35px
    }
    .career-card-icon-img {
        padding: 20px;
        width: 100%;
    }
    .career-card-title .clr-txt {
        text-align: start;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        margin: 40px auto 20px;
    }
    .career-card-icon {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        height: 100px;
        width: 100px;
        border: 1px solid white;
        border-radius: 100px;
        box-shadow: 0px 0px 60px var(--prosouls-blue);
        background: #021928;
    }
    .cstm-padding-top{
        padding-top: 0;
    }
    .career-second-module-heading.eight h1{
        font-size: 40px;
        text-align: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
    }
    .approach1234 {
        margin-left: 0;
        margin-top: 20px;
    }
    .hello123{
        width: 90%;
        margin: 0 auto;
    }
.accordion-body h1,
.accordion-body h2,
.accordion-body h3,
.accordion-body h4,
.accordion-body h5,
.accordion-body h6,
.accordion-body p
{
    text-align: start !important;
}

}
.accordion-button:not(.collapsed) {
    color: var(--prosouls-blue) !important;
    /* background-color: var(--bs-accordion-active-bg); */
    /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}
.btn45{
    border-color: var(--prosouls-blue) !important;
    color: var(--prosouls-blue) !important;
}
.btn45:hover{
    background-color: var(--prosouls-blue) !important;
    color: var(--prosouls-black) !important;
}
.accordion-button:focus{
    box-shadow: none;
}

@media screen and (min-width: 901px) {

    .company_overview {
        color: var(--prosouls-white);
    }

    .approach1234 {
        font-size: 50px;
        word-spacing: 3vw;
        font-family: Avenir;
        color: var(--prosouls-white);
        margin-left: 12%;
        word-spacing: normal;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        font-weight: 900;
    }

    .careerpara {
        margin-top: 20px;
        font-size: 15px;
        font-family: Avenir;
        color: var(--prosouls-white);
        text-align: start;
        letter-spacing: 2px;
        word-spacing: normal;
    }

    .hello123 {
        width: 35vw;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .container1234 {

        display: flex;
        align-items: center;
        align-content: center;
        text-align: center;
        justify-content: center;
        background-color: "#021928";
        padding-top: 10%;

    }

    .img1234 {
        width: 600px;
        position: relative;
    }

    .btn45 {
        width: 14vw;
        align-items: flex-start;
        cursor: pointer;
        display: grid;
        align-items: center !important;
        font-size: "20px";
        color: "white";
        margin-top: 20px;

    }


    .approach12345 {
        margin-top: 100px;
        font-size: 50px;
        word-spacing: 1vw;
        font-family: Avenir;
        color: var(--prosouls-white);
        margin-left: 12%;
        word-spacing: normal;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .job {
        margin-top: 10px;
        font-size: 20px;
        font-family: Avenir;
        color: var(--prosouls-white);
        text-align: center;
        word-spacing: normal;
    }

    .jobopening {
        width: 80%;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 5%;
    }

    .accordion {
        --bs-accordion-bg: transparent;
        --bs-accordion-color: var(--prosouls-white);
        --bs-accordion-btn-color: var(--prosouls-white) --bs-accordion-active-color: transparent;
        --bs-accordion-active-bg: transparent;
    }

    .dropdowns {
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
        position: relative;
    }

    .career {
        background-color: #021928;

    }

    .headingdd {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin-top: 20px;
        font-size: 35px;
        margin-left: 10px;
    }

    .jobtype {
        margin-top: 50px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin-left: 20px;
        font-size: 20px;
        word-spacing: normal;
    }

    .jobtype1 {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin-left: 20px;
        font-size: 20px;
        word-spacing: normal;
    }

    .email {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        font-size: 30px;
        margin-left: 20px;
        margin-top: 50px;
    }

    .frontsales {
        margin-top: 30px;
        align-items: flex-start;
        text-align: start;
        margin-left: 20px;
    }
    .career-second-module{
        margin: 5% auto;
    }
    .career-cards-flex {
        margin: 7.5% 0;
        display: table;
        border-spacing: 0px;
    }
    .career-cards-column {
        border: 1px solid white;
        position: relative;
        display: table-cell;
        width: 25%;
        padding: 30px;
    }
    .career-card-icon-img {
        padding: 20px;
        width: 100%;
    }
    .career-card-title .clr-txt {
        text-align: start;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        margin: 40px auto 20px;
    }
    .career-card-icon {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        height: 100px;
        width: 100px;
        border: 1px solid white;
        border-radius: 100px;
        box-shadow: 0px 0px 60px var(--prosouls-blue);
        background: #021928;
    }
    .cstm-padding-top{
        padding-top: 0;
    }
    .jobopening .accordion-header button:focus{
        outline: none;
        box-shadow: none;
    }
    .jobopening .accordion-body div{
        padding: 20px; 
        text-align: justify;
    }
    .jobopening .accordion-body .btn45 {
        display: flex;
        margin: 20px auto;
    }
}