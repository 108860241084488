@media screen and (min-width: 901px) {
    .heading {
      display: block;
      font-family: Avenir;
      color: var(--prosouls-white);
      text-align: start;
      font-size: 40px;
      /* margin-left: 24%; */
  
      /* margin-top: 20px; */
    }
  
    .carousel-control-next-icon {
      visibility: hidden;
    }
  
    .carousel-control-prev-icon {
      visibility: hidden;
    }
    .react-multi-carousel-list {
      margin: 0 5% !important;
  }
    .work {
      margin-top: 10%;
    }
  
    .textwork {
      text-align: justify;
      /* margin-top: 50px; */
      /* margin-left: 6%; */
      /* margin-right: 30%; */
      font-size: 16px;
      font-family: Avenir;
      color: var(--prosouls-white);
      /* margin-bottom: 10%; */
    }
  
    .colm {
      display: flex;
      /* margin-left: 80px; */
      width: 70%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      column-gap: 200px;
    }
  
    .img1 {
      height: 25vw;
      width: 35vw;
    }
  
    .hello111 {
      margin-top: 5%;
    }
  
    .heading1 {
      /* margin-left: 15%; */
      width: 30%;
      /* padding-top: 20px; */
    }
  
    .text1 {
      /* margin-right: 10%; */
      width: 50%;
      text-align: start;
      align-items: flex-start;
    }
  }
  
  @media screen and (max-width: 900px) {
    .heading {
      display: block;
      font-size: 25px;
      font-family: Avenir;
      color: var(--prosouls-white);
      margin-top: 20px;
    }
  
    .carousel-control-next-icon {
      visibility: hidden;
    }
  
    .carousel-control-prev-icon {
      visibility: hidden;
    }
  
    .work {
      margin-top: 10%;
    }
  
    .textwork {
      text-align: center;
      margin-top: 25px;
      margin-left: 12%;
      margin-right: 12%;
      font-size: 14px;
      font-family: Avenir;
      color: var(--prosouls-white);
      margin-bottom: 10%;
    }
  
    .img1 {
      height: 25vw;
      width: 35vw;
    }
  
    .hello111 {
      margin-top: 5%;
    }
  }
  
  .w-50 {
    margin-right: 50px !important;
    width: 80% !important;
  }
  
  .react-multi-carousel-list {
    margin: 5%;
  }