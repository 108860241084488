.footer-bg-heading {
  font-size: 70px;
  font-family: Avenir;
  font-weight: bold;
  color: var(--prosouls-white);
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
}

.footer-bg-para {
  margin-top: 20px;
  font-size: 35px;
  font-family: 'Avenir';
  color: var(--prosouls-blue);
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
}

.footer-news-letter-parent {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.newsletter-subscription {
  width: 55%;
}

.mng-width {
  width: max-content;
}

.newsletter-heading {
  text-align: left;
  font-size: 35px;
  font-family: "Avenir";
  color: var(--prosouls-white);
  font-weight: 700;
  letter-spacing: 1px;
}

.sitemap {
  width: 50%;
}

.inquiries {
  width: 50%;
}

.inquiries-content,
.inquiries-content a {
  text-align: start;
  color: var(--prosouls-white);
  font-family: "Avenir";
  text-decoration: none;
}

.newsletter-input-btn-parent {
  margin-top: 50px;
  padding: 10px 0 10px 0;
  font-size: 18px;
  font-family: "Avenir";
  border-bottom: 1px solid white;
}

.newsletter-input {
  background: transparent;
  outline: none;
  width: 90%;
  display: inline-block;
  border: none;
  font-family: "Avenir";
  color: var(--prosouls-white);
}

.newsletter-btn {
  display: inline-block;
  width: 10%;
  background: transparent;
  outline: none;
  border: none;
  color: var(--prosouls-white);
  text-align: flex-end;
}

.newsletter-icon {
  font-weight: 100;
}

.newsletter-input::placeholder {
  color: var(--prosouls-white);
}

.sitemap ul {
  text-align: left;
  padding: 0;
}

.sitemap li {
  list-style: none;
  text-align: left;
  margin-bottom: 13px;
}

.sitemap-names {
  color: var(--prosouls-white);
  text-decoration: none;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  transition: all ease-in-out 0.3s;
}

.footer-bg {
  display: grid;
  align-content: center;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  background-image: url(../../assets/globe-2mb.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: lighten;
  background-position: center;
  margin-bottom: 50px;
}

.footer-details {
  width: 45%;
}

.footer-widget-details {
  display: flex;
}

.footer-widget-address {
  display: flex;
  justify-content: flex-end;
}

.mng-mt {
  margin-top: 30px;
}

.footer-usa-address {
  width: 25%;
  margin-top: 50px;
  color: var(--prosouls-white);
  font-family: "Avenir";
  text-align: start;
}

.footer-uk-address {
  width: 25%;
  margin-top: 50px;
  color: var(--prosouls-white);
  font-family: "Avenir";
  text-align: start;
}

.footer-pk-address {
  width: 25%;
  margin-top: 50px;
  color: var(--prosouls-white);
  font-family: "Avenir";
  text-align: start;
}

.footer-accordion {
  padding-bottom: 20px !important;
}

.footer-accordion button:active,
.footer-accordion button:focus {
  outline: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  color: var(--prosouls-white);
  box-shadow: none;
}

.footer-accordion-title button {
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
  color: var(--prosouls-white);
  font-family: "Avenir";
  outline: none;
  border: none !important;
}

.footer-accordion button:hover {
  outline: none;
  box-shadow: none;
}

a.sitemap-names:hover {
  color: var(--prosouls-blue) !important;
}

.footer-accordion .accordion-item {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
}

.footer-accordion .accordion-button {
  font-weight: 700;
  font-family: "Avenir";
  color: var(--prosouls-white);
}

.footer-accordion .accordion-button::after {
  background: url("../../assets/plus-icon-accordion.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.footer-accordion .accordion-button:not(.collapsed)::after {
  background: url("../../assets/minus-icon-accordion.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.footer-link, .footer-link:hover {
  color: var(--prosouls-blue);
  text-decoration: none;
}

.footer-accordion .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.services-list {
  color: var(--prosouls-white);
  font-family: "Avenir";
}

.footer-services-parent {
  display: flex;
  width: 100%;
}

.footer-services-parent div {
  width: 25%;
}

.services-widget ul {
  padding: 20px;
  margin: 0;
}

.services-widget ul li {
  text-align: left;
  list-style: none;
}

.services-widget ul li p {
  margin: 0;
  padding: 5px;
  font-family: "Avenir";
}

.footer-copyrights {
  padding: 16px 20px;
  display: flex;
  width: 100%;
  align-items: center;
}

.footer-copyrights .copyright,
.footer-copyrights .social-platform,
.footer-copyrights .cookies {
  width: 33.3%;
}

.copyright p {
  text-align: start;
  color: var(--prosouls-white);
  margin: 0;
  font-family: "Avenir";
}

.social-platform {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.footer-social-link {
  border: 1px solid #001928;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 100px;
  color: #001928;
}

.footer-social-link:hover {
  color: #001928;
}

.cookies a,
.cookies a:hover {
  text-decoration: none;
  color: var(--prosouls-white);
  float: right;
  font-family: "Avenir";
}
.cstm-recaptcha {
  position: relative;
}
/* Form BUTTON CSS */

.cstm-footer-form-button {
  min-width: 100%;
  font-family: "Avenir", sans-serif;
  font-size: 20px;
  /* text-transform: uppercase; */
  letter-spacing: 1.3px;
  font-weight: 700;
  color: var(--prosouls-blue);
  background: transparent;
  /*background: linear-gradient(180deg, var(--prosouls-blue), var(--prosouls-blue) );*/
  border: none;
  border: 2px solid var(--prosouls-blue);
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--prosouls-blue);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.cstm-footer-form-button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 5px solid var(--prosouls-blue);
  box-shadow: 0 0 60px var(--prosouls-blue);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.cstm-footer-form-button:hover {
  color: black;
  background: var(--prosouls-blue);
  transform: translateY(-6px);
}

.cstm-form-button-parent button:hover::before {
  opacity: 1;
}

.cstm-footer-form-button::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 5px solid var(--prosouls-blue);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.cstm-footer-form-button:hover::after,
.cstm-footer-form-button:focus::after {
  animation: none;
  display: none;
}
.alert-box {
  background-color: #ff00005e;
  color: #ffffff;
  padding: 10px;
  margin-top: 10px;
}
.alert-text {
  margin: 0;
}
.alert-box-success{
  background-color: #24b10888;
  color: #ffffff;
  padding: 10px;
  margin-top: 10px;
}

@keyframes ring {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer-bg {
    min-height: 250px;
  }

  .footer-bg-heading {
    font-size: 30px;
  }

  .footer-bg-para {
    font-size: 25px;
    margin-top: 5px;
  }

  .footer-news-letter-parent {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .newsletter-heading {
    text-align: center;
    font-size: 6vw;
  }

  .newsletter-subscription {
    width: 100%;
  }

  .sitemap {
    width: 100%;
  }

  .inqueries {
    width: 100%;
  }

  .footer-details {
    width: 100%;
    /* margin: 0 auto; */
  }

  .footer-widget-details {
    margin-top: 50px;
  }

  .footer-widget-address {
    display: grid;
    justify-content: center;
    width: 87%;
    margin: 0 auto;
  }

  .footer-usa-address {
    width: 100%;
  }

  .footer-pk-address {
    width: 100%;
  }

  .footer-uk-address {
    width: 100%;
  }

  .footer-usa-address p br,
  .footer-pk-address p br,
  .footer-uk-address p br {
    display: none;
  }

  .footer-services-parent {
    display: grid;
    width: 100%;
  }

  .footer-services-parent div {
    width: 100%;
  }

  .footer-accordion {
    padding-bottom: 20px !important;
    width: 87%;
    margin: 0 auto;
  }

  .copyright p {
    text-align: center;
    color: var(--prosouls-white);
    margin: 0 0 20px 0;
  }

  .footer-copyrights {
    padding: 16px 20px;
    display: grid;
    width: 100%;
    align-items: center;
  }

  .footer-copyrights .copyright,
  .footer-copyrights .social-platform,
  .footer-copyrights .cookies {
    width: 100%;
  }

  .cookies a {
    float: none;
  }

  .cookies {
    margin: 20px 0 40px 0;
  }
  .cstm-recaptcha {
    position: relative;
    margin-bottom: 15px;
  }
}

/* ======= New Footer CSS ======= */
.footer-border-style {
  margin: 50px 0 0 0;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}
:root {
  --d: 8000ms;
  --angle: 360deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: var(--prosouls-blue);
  --c2: transparent;
}
.address-container {
  border: 0.35rem solid;
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;

  border-radius: 4px;
}
/* .address-container:nth-child(2) {
	border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 30;
	animation: borderRadial var(--d) linear infinite forwards;
} */
@keyframes borderRotate {
  100% {
    --angle: 1440deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}
.address-parent {
  padding: 40px 50px;
  display: flex;
  column-gap: 10%;
}
.prosouls-global {
  width: 40%;
}
.footer-content-prosouls-h4 {
  text-transform: uppercase;
  font-weight: 700;
}
.footer-content-prosouls-h1 {
  text-align: start;
  font-size: 700;
  color: var(--prosouls-blue);
}
.address-column {
  width: 50%;
}
.address-column-flex {
  display: flex;
  justify-content: flex-end;
}
.address-column-display {
  width: 50%;
  /* display: flex;
    align-items: flex-end;
    justify-content: space-evenly; */
  border: 1px solid white;
  padding: 10px 0;
}
.address-usa,
.address-uk {
  border-right: none;
}
.address-img {
  width: 65px;
  height: 65px;
  object-fit: contain;
}
.address-column-details {
  width: 50%;
  display: flex;
  margin-top: 15px;
  /* align-items: flex-end; */
  /* justify-content: space-evenly; */
  padding: 10px 5px;
}
.address-desktop-behviour {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact-us-parent {
  padding: 60px 0px;
  display: flex;
  column-gap: 10%;
}
.hyphen-heading {
  display: flex;
  align-items: center;
}
.horizintal-line {
  width: 15px;
  background-color: var(--prosouls-white);
  height: 1px;
  margin-right: 10px;
}
.contact-us-column-form-parent {
  width: 48%;
}
.contact-us-form-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.contact-footers-col-6 {
  width: 45%;
}
.cstm-footer-form {
  width: 100%;
  font-size: 20px;
  background: #001a28;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: var(--prosouls-white);
  padding: 10px 0;
}
.cstm-footer-form:hover,
.cstm-footer-form:focus,
.cstm-footer-form:active,
.cstm-footer-form::placeholder {
  outline: none;
  box-shadow: none;
  color: var(--prosouls-white);
}
.cstm-footer-form select option {
  padding: 10px 0;
}
.cstm-footer-form::-webkit-outer-spin-button,
.cstm-footer-form::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.footer-bg-white {
  background-color: var(--prosouls-white);
  position: relative;
  padding-bottom: 30px;
}
.newsletter-column-flex {
  padding: 50px 0 20px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #001928;
}
.footer-widget {
  width: 30%;
}
.bg-white-text-clr {
  color: #001a28;
  text-align: start;
}
.newsletter-input-footer {
  width: 100%;
  padding: 10px 20px;
  font-size: 20px;
  color: #001a28;
  border: 2px solid #001a28;
}
.newsletter-input-footer::placeholder {
  color: #001a28;
}
.newsletter-btn-footer {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  background: #001928;
  color: var(--prosouls-white);
  display: block;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  font-family: "Avenir";
  cursor: pointer;
}
.get-free-pricing-btn,
.get-free-pricing-btn:hover,
.get-free-pricing-btn:focus,
.get-free-pricing-btn:active {
  font-size: 16px;
  padding: 16px 0;
  color: var(--prosouls-white);
}
.footer-logo-img {
  width: 100%;
  /* mix-blend-mode: difference; */
  margin-bottom: 15px;
  height: auto !important;
}
.copyright-column-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0;
}
.copyroght-widget {
  width: 50%;
}
.copyright-footer-links,
.copyright-footer-links:hover {
  color: #001928;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
}
.footer-link-widget {
  display: flex;
  column-gap: 20px;
  margin: 0;
  padding: 0;
}
.footer-link-widget li {
  list-style: none;
}
.footer-submit-btn {
  border: 2px solid white;
  color: var(--prosouls-white);
  background: transparent;
  text-transform: uppercase;
  padding: 10px 10px 8px 10px;
  width: 100%;
  text-decoration: none;
  font-family: "Avenir";
  font-size: 20px;
}
.desktop-d-none {
  display: none;
}
.goodfirms-image{
  width: 70%;
  margin:0 auto;
}
@media only screen and (max-width: 991.98px) {
  .address-parent {
    padding: 40px 20px;
    display: grid;
  }
  .prosouls-global {
    width: 95%;
    margin: 0 auto 25px;
  }
  .prosouls-global > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  .footer-content-prosouls-h1 {
    text-align: center !important;
  }
  .address-column {
    width: 95%;
    margin: 0 auto;
  }
  .desktop-d-none {
    display: block;
  }
  .address-column-flex {
    display: grid;
  }
  .address-column-display {
    width: 100%;
    display: grid;
  }
  .address-column-details {
    width: 100%;
  }
  .address-usa,
  .address-uk {
    border-right: 1px solid white;
    border-bottom: none;
  }
  .mob-d-none {
    display: none;
  }
  .address-desktop-behviour {
    display: grid;
    justify-content: space-around;
    align-items: center;
  }
  .contact-us-parent {
    display: grid;
  }
  .contact-us-parent > h1,
  .contact-us-parent > h2,
  .contact-us-parent > h3,
  .contact-us-parent > h4,
  .contact-us-parent > h5,
  .contact-us-parent > h6,
  .contact-us-parent > p,
  .contact-us-parent > h3.clr-txt,
  .contact-us-parent > h1.clr-txt {
    text-align: center !important;
  }
  .hyphen-heading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-us-column-form-parent {
    width: 95%;
    margin: auto;
  }
  .contact-us-form-footer {
    display: grid;
    width: 100%;
    justify-content: unset;
    margin: 0;
  }
  .contact-footers-col-6 {
    width: 100%;
    margin-bottom: 20px;
  }
  .newsletter-column-flex {
    display: grid;
    padding: 25px 20px;
  }
  .footer-widget {
    width: 95%;
    margin: 0 auto 20px;
  }
  .copyright-column-flex {
    display: grid;
    justify-content: center;
  }
  .footer-link-widget {
    display: grid;
  }
  .footer-link-widget li {
    margin-bottom: 5px;
  }
}


.react-tel-input .form-control {
  font-size: 20px !important;
  background: #021928 !important;
  border: none !important;
  border-radius: 0 !important;
  font-family: 'Avenir' !important;
  color: white !important;
  height: auto !important;
  width: 100% !important;
  outline: none !important;
  border-bottom: 2px solid white !important;
  padding: 12.5px 0;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  border-radius:0 !important;
  background-color: #001928 !important;
}
.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background: #021928 !important;
  border-radius: 0 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #021928 !important;
  border-radius: 0 !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: #021928 !important;
}
.react-tel-input .selected-flag {
  outline: none !important;
  background: #021928 !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid white !important;
}
.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 4px solid #fff !important;
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #ffffff !important;
}
.react-tel-input .country-list {
  outline: none !important;
  text-align: start !important;
  min-width: 100% !important;
  background-color: #021928 !important;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.35) !important;
  border-radius: 0 !important;
}
.react-tel-input .country-list .country {
  color: white !important;
}
.react-tel-input .country-list .country:hover {
  background-color: var(--prosouls-blue) !important;
}
.react-tel-input .country-list .country .dial-code {
  color: #ffffff !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: var(--prosouls-blue) !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.country-list::-webkit-scrollbar-thumb {
  /* background-image: -webkit-gradient(linear, left bottom, left top, color-stop(.5, #a520ca), color-stop(1, #2681cc)); */
  background-image: linear-gradient(to bottom, #29ABE2 , #00FFFF) !important;
  border-radius: 10px !important;
}
.country-list::-webkit-scrollbar-track {
  border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.1) !important;
}
.country-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
/* .country-list::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  */
/* Handle */
/* .country-list::-webkit-scrollbar-thumb {
  background: #888; 
} */

/* Handle on hover */
/* .country-list::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */