@media screen and (min-width: 901px) {
  .heading {
    display: block;
    font-family: Avenir;
    color: var(--prosouls-white);
    text-align: start;
    font-size: 40px;
    /* margin-left: 24%; */

    /* margin-top: 20px; */
  }

  .carousel-control-next-icon {
    visibility: hidden;
  }

  .carousel-control-prev-icon {
    visibility: hidden;
  }

  .work {
    margin:5% 10% !important;
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: 100px;
  }

  .textwork {
    text-align: justify;
    color: var(--prosouls-white);
    font-size: 16px;
    font-family: Avenir;
  
  }
.work-image{
  width: 90%;

}


  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }
 
  

}

@media screen and (max-width: 900px) {
  .heading {
    display: block;
    font-size: 25px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-top: 20px;
  }

  .carousel-control-next-icon {
    visibility: hidden;
  }

  .carousel-control-prev-icon {
    visibility: hidden;
  }

  .work {
    display:grid;
    margin-top: 10%;
    overflow: hidden;
  }

  .textwork {
    text-align: center;
    margin-top: 25px;
    margin-left: 12%;
    margin-right: 12%;
    font-size: 14px;
    font-family: Avenir;
    color: var(--prosouls-white);
    margin-bottom: 10%;
  }

  .img1 {
    height: 25vw;
    width: 35vw;
  }

  .hello111 {
    margin-top: 5%;
  }
  .work-image{
  width: 100%;
  order: 1;
  }
  .colm{
    width: 100%;
    order: 2;
  }
}

.w-50 {
  margin-right: 50px !important;
  width: 80% !important;
}

.react-multi-carousel-list {
  margin: 5%;
}
/* .image{
  transform: translateY(-2%);
animation: floater 2s infinite;
transition: ease 0.5s;
}
@keyframes floater {
  0%{transform: translateY(-2%);transition: ease 0.5s;}
  50%{transform: translateY(2%);transition: ease 0.5s;}
} */