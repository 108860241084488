.quality-assurance-expertise-banner{
    background: url(../../../assets/quality-assurance/Quality-Assurance.png);
    background-position: 80% center;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: 35%;     
}
.clr-text-white{
    color: var(--prosouls-white);
    font-family: 'Avenir';
}
.quality-assurance-expertise-first-module {
    padding: 20% 0 15% 0;
    width: 35%;
    text-align: left;
    /* margin: 0 0 0 12.1%; */
}
.quality-assurance-expertise-first-module-btn-parent{
    margin: 20px 0;
}
.quality-assurance-expertise-first-module-btn{
    transition: all ease-in 0.1s;
    border: 2px solid var(--prosouls-blue);
    color: var(--prosouls-blue);
    text-transform: uppercase;
    padding: 10px 10px 8px 10px;
    width: max-content;
    text-decoration: none;
    font-family: 'Avenir';
    display: flex;
    align-items: center;
}
.quality-assurance-expertise-first-module-btn-icon{
    margin: 0 15px 0 5px;
}
.quality-assurance-expertise-first-module-btn:hover {
    color: var(--prosouls-blue) ;
    font-family: 'Avenir';
}
.quality-assurance-expertise-first-module-btn:hover .quality-assurance-expertise-first-module-btn-icon{
    margin: 0 5px 0 15px;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}
.quality-assurance-expertise-second-module-flex{
    display: flex;
    /* margin: 5% 0; */
    width: 100%;
    overflow: hidden;
}
.quality-assurance-expertise-second-module-column.one{
    /* display: table-cell; */
  /* padding: 16px; */
    width: 40%;
}
.quality-assurance-expertise-second-module-parent .container{
    max-width: 1920px;
    padding: 0;
}
.quality-assurance-expertise-second-module-column.solid-bg{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    display: flex;
    align-items: center;
    width: 65%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.quality-assurance-stroke-font{
    font-size: 140px;
    font-family: 'Avenir';
    text-transform: uppercase;
    line-height: 0.9;
    margin-left: 0px;
    text-align: start;
    letter-spacing: 25px;
    color: var(--prosouls-white);
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
}
.quality-assurance-expertise-number-flex{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    column-gap: 10%;
    align-items: center;
}
.quality-assurance-expertise-number-column{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    padding: 20px 50px;
    width: 35%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.quality-assurance-expertise-number-column h1{
    font-size: 50px;
    text-align: center;
    font-family: 'Avenir';
    font-weight: 900;
    margin: 0;
}
.quality-assurance-expertise-number-column p{
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-family: 'Avenir';
}
.quality-assurance-solid-bg-ul{
    columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  /* list-style: none; */

}
.quality-assurance-solid-bg-list-items{
    text-align: start;
    color: var(--prosouls-white);
}
.quality-assurance-solid-bg-list-items p{
    font-size: 20px;
}
.quality-assurance-expertise-solid-bg-content{
    width: 70%;
    margin: 0 auto 0 60px;
}
.quality-assurance-expertise-solid-bg-content h1 , h2, h3{
    text-align: start;
    margin-bottom: 20px;
}
.quality-assurance-expertise-third-module-parent .container {
    display: flex;
    column-gap: 150px;
    align-items: flex-start;

}
.quality-assurance-expertise-third-module-details{
    margin: 40px 0px;
    padding: 20px;
    text-align: start;
        display: flex;
    column-gap: 25px;   
    align-items: flex-start;
    /* background: linear-gradient(139deg , transparent, var(--prosouls-blue)36); */
}
.quality-assurance-expertise-third-module-parent .sub-title{
    font-size: 50px;
}
.quality-assurance-expertise-third-module-parent .main-title{
    font-size: 70px;
}
.clr-text-blue{
    color: var(--prosouls-blue);
    font-weight: 400;
    font-family: 'Avenir';
}
/* .quality-assurance-expertise-third-module-parent{
    background: url(../../../assets/quality-assurancebuiding.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;
    padding: 100px 0;
} */
.quality-assurance-expertise-third-module {
    /* padding: 10% 0 10% 0;     */
    text-align: start;
    width: 50%;
    margin-bottom: 100px;
}
.quality-assurance-expertise-third-module-icon{
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
.quality-assurance-expertise-fourth-bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.quality-assurance-expertise-underline{
    height: 5px;
    width: 20%;
    background: var(--prosouls-blue);
    border-radius: 10px;
}
.quality-assurance-expertise-sixth-module-parent{
    margin: 100px 0;
}
.quality-assurance-expertise-sixth-module-parent h1{
    width: 60%;
    text-align: start;
}
.quality-assurance-expertise-get-qoute , .quality-assurance-expertise-get-qoute:hover{
    padding: 10px 25px;
    background-color: var(--prosouls-blue);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--prosouls-white);
    text-decoration: none;
}
.get-qoute-parent-quality-assurance-expertise{
    text-align: start;
    margin: 50px 0;
}
.quality-assurance-table-section{
    width: 100%;
}
.quality-assurance-table{
    width: 100%;
}
.quality-assurance-table-head{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    text-align: center;
    padding: 20px;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.quality-assurance-table-head h1{
    text-align: center;
    font-size: 60px;
    margin:0;
    font-weight: 700;
}
.quality-assurance-table tbody tr td{
    width: 33%;
    border: 2px solid white ;
    padding: 20px 40px ;
    vertical-align: baseline;
}
.quality-assurance-table tbody tr td p{
    text-align: start;
    /* margin: 0; */
    /* font-size: 20px; */
    font-weight: 500;
}
.quality-assurance-table tbody tr td h3{
    text-align: start;
    font-size: 30px;
    color: var(--prosouls-white);
    text-transform: uppercase;
}
.quality-assurance-table tbody tr td h4{
    text-align: start;
    font-size: 25px;
    color: var(--prosouls-white);
}
.clr-txt-blue{
    color: var(--prosouls-blue);
}
.quality-assurance-expertise-fourth-module-parent{
    margin: 5% 0;
}
.quality-assurance-content{
    font-size: 25px;
}
.quality-assurance-table-img{
    object-fit: contain;
    width: 100%;
    height: auto;
    vertical-align: text-top;
}
.quality-assurance-third-module-section {
    position: relative;
    display: table;
    width: 100%;
    /* border-bottom: 2px solid white; */
}
/* .quality-assurance-second-module-bg{
    background: url(http://localhost:3000/static/media/quality-assurance-second-module-background.2f32bb9….png);
    background-repeat: no-repeat;
    background-size: 100% 40%;
    background-position: bottom center;
} */
.quality-assurance-third-module-section__item.column-one {
    /* border-right: 2px solid white; */
    display: table-cell;
    /* padding: 40px 30px 40px 30px; */
    width: 50%;
}
.quality-assurance-third-module-section__item.column-two {
    display: table-cell;
    width: 50%;
    padding: 40px 40px 0 40px;
    vertical-align: bottom;
}
.quality-assurance-fourth-module-section {
    display: table;
    width: 100%;
    /* border-bottom: 2px solid white; */
    /* margin: 2rem auto; */
}
.quality-assurance-fourth-module-section__item{
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}
.quality-assurance-fourth-module-section__item.column-one{
    /* border-right: 2px solid white; */
    padding: 20px 30px 40px 0px;
}
.quality-assurance-fourth-module-section__item.column-two{
    padding: 20px 0px 40px 30px;
}
.quality-assurance-fourth-inner-module-section {
    display: table;
    /* border-bottom: 2px solid white; */
    /* margin: 2rem auto; */
}
.quality-assurance-fourth-inner-module-section__item{
    width: 50%;
    display: table-cell;
}
.quality-assurance-fourth-inner-module-section__item.column-one{
    /* border-right: 2px solid white; */
    padding: 10px 20px 10px 0;
}
.quality-assurance-fourth-inner-module-section__item.column-two{
    padding :10px 20px;
}

.quaity-assurance-eight h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 70px;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 70px;
    grid-gap: 30px;
    align-items: center;
    align-content: center;
  }
  
  .quaity-assurance-eight h1:after,.quaity-assurance-eight h1:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ccc;
    background-color:#f8f8f8;
  }
  .mng-line-height{
    line-height: 2;
  }
  .quality-assurance-techs-title h1{
    font-weight: 900;
    text-transform: uppercase;
  }
  .quality-assurance-tech-container {
    margin: 2% auto;
    display: flex;
    justify-content: center;
    column-gap: 2%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .quality-assurance-tech-img{
    overflow: hidden;
    object-fit: contain;
    width: 100%;
  }
  .quality-assurance-tech-item {
    width: 18%;
    margin-bottom: 2%;

  }
  .devops-tech-container{
    column-gap: 2%;
  }
  .devops-tech-item{
    width: 18%;
  }
  .quality-assurance-info-card-flex{
    display: table;  
    border-collapse: separate;
    border-spacing: 50px 25px;
  }
  .quality-assurance-info-card-column{
    display: table-cell;
    width: 47.5%;
    border: 2px solid white;
    padding: 20px;
    margin-bottom: 5%;
  }
  .quality-assurance-manage-services-content{
    width: 75%;
    margin: 20px auto;
  }
  .quality-assurance-info-card-column.manage-service{
    display: table-cell;
    padding: 30px 20px;
    margin-bottom: 5%;
    border: none;
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
  }
  .quality-assurance-manage-service-inner-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
  }
  .quality-assurance-floating-container{
    position: absolute;
    padding: 30px;
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    left: 20%;
    width: 65%;
    right: 15%;
    top: 6%;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
  }
  .quality-assurance-cards-flex {
    margin: 7.5% 0;
    display: flex;
    justify-content: center;
    border-spacing: 25px;
}
.quality-assurance-cards-column {
    border: 1px solid white;
    position: relative;
    height: max-content;
    width: 30%;
    padding: 30px;
}
.quality-assurance-cards-column.column-one{
    border-right: none;
}
.quality-assurance-cards-column.column-three{
    border-left: none;
}
.quality-assurance-card-icon {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 100px;
    width: 100px;
    border: 1px solid white;
    border-radius: 100px;
    box-shadow: 0px 0px 60px var(--prosouls-blue);
    background: #021928;
}
.quality-assurance-card-icon-img {
    padding: 20px;
    width: 100%;
}
.quality-assurance-card-title .clr-txt {
    text-align: center;
    font-weight: 500;
    /* text-transform: uppercase; */
    font-size: 25px;
    font-family: 'Avenir';
    margin: 40px auto 20px;
}
.quality-assurance-mng-height-column{
    margin-bottom: 98px;
}
.quality-assurance-card-details ul , .quality-assurance-card-details li{
    color: var(--prosouls-white);
    text-align: left;
    padding-top: 5px;
    font-weight: 100;
    font-size: 15px;
    letter-spacing: 1px;
}
.quality-assurance-fourth-module-heading-parent{
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    text-align: center;
    padding: 20px;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.quality-assurance-fourth-module-heading-parent h1{
    text-align: center;
    font-size: 60px;
    margin: 0;
    font-weight: 700;
}
.quality-assurance-fourth-module-flex{
    display: table;
    width: 100%;
}
.quality-assurance-fourth-module-column{
    width: 50%;
    display: table-cell;
    border: 2px solid white;
    border-top: none;
}
.quality-assurance-fourth-module-column.one{
    border-right: none;
}
.quality-assurance-fourth-module-inner-column{
    padding: 30px;
}
.quality-assurance-fourth-module-inner-column ul , .quality-assurance-fourth-module-inner-column li{
    color: var(--prosouls-white);
    text-align: left;
    padding-top: 5px;
    font-weight: 100;
    margin: 0;
    font-size: 18px;
    letter-spacing: 1px;
}
.quality-assurance-fourth-module-inner-column.two{
    border-top: 2px solid white;
}
.quality-assurance-our-clinets{
    margin: 5% auto;
}
.quality-assurance-second-module-content{
font-size: 21px;

}
.quality-assurance-number-flex {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    column-gap: 5%;
    align-items: center;
    flex-wrap: wrap;
}
.quality-assurance-number-column {
    /* background-image: linear-gradient(to right, #29ABE2 , #00FFFF); */
    padding: 20px;
    width: 47.5%;
    margin: 5% auto 0;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.quality-assurance-number-column h1 {
    font-size: 50px;
    text-align: start;
    font-family: 'Avenir';
    font-weight: 900;
    margin: 0;
}
.quality-assurance-number-column p {
    font-size: 20px;
    text-align: start;
    margin: 0;
    font-family: 'Avenir';
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {

    .quality-assurance-expertise-banner {
        background-size: 80%;
        background-position: center 120px;
    }
    .quality-assurance-expertise-first-module {
    padding: 120% 0 15% 0;
    width: 100%;
    text-align: center;
}
.quality-assurance-expertise-second-module-flex {
    display: grid;
}
.quality-assurance-expertise-second-module-column.one {
    width: 100%;
}
.quality-assurance-stroke-font {
    font-size: 40px;
    text-align: center;
    letter-spacing: 3px;
}
.quality-assurance-expertise-second-module-column.solid-bg {
    width: 100%;
}
.quality-assurance-expertise-solid-bg-content {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
.quality-assurance-third-module-section {
    display: grid;
}
.quality-assurance-third-module-section__item.column-one {
    width: 100%;
}
.quality-assurance-floating-container {
    position: inherit;
    background: transparent;
    padding: 20px;
    left: 0%;
    width: 100%;
    right: 0%;
    top: 0%;
}
.quality-assurance-third-module-section__item.column-two {
    width: 100%;
    padding: 20px;
}
.quality-assurance-number-flex {
    display: grid;
    margin-top: 15px;
}
.quality-assurance-number-column {
    padding: 20px;
    width: 100%;
}
.quaity-assurance-eight h1 {
    font-size: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px;
    grid-gap: 15px;
  }
  .quality-assurance-cards-flex {
    display: grid;
}
.quality-assurance-cards-column {
    border: 1px solid white !important;
    position: relative;
    height: max-content;
    width: 100%;
    padding: 20px;
    margin: 50px auto;
}
.quality-assurance-tech-item {
    width: 30.8%;
    margin-bottom: 2%;
}
.quality-assurance-expertise-third-module-parent .container {
    display: contents;
}
.quality-assurance-expertise-third-module{
    width: 87%;
    margin: 0 auto;
}
.quality-assurance-expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
}
.quality-assurance-expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
}
.quality-assurance-expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg , transparent, var(--prosouls-blue)36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
}
.quality-assurance-expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
}