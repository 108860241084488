.bg-blue-card {
  background: #00aee3;
  border-radius: 0;
  margin: 10px;
}

.card .clr-txt {
  font-weight: 600;
  font-size: 18px;
}

.testimonial-user-image {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background: var(--prosouls-white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial-user-image img{
  height: 80px;
  object-fit: cover;
  width: 80px;
  border-radius: 100px;
}

.user-profile-parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.testimonial-content {
  padding: 16px;
  height: 200px;
  background: url(../../../assets/testimonials-bg-qoutes.png);
  background-position: 20px 20px;
  background-repeat: no-repeat;
  background-size: 60px;
  padding: 70px 0 0 0;
}

.css-ryrseu-MuiRating-root {
  font-size: 30px !important;
}
.testimonial-desciption-text{
      width: 80%;
    margin: 0 25px 0 auto;
}
.testimonial-desciption-text p{
  color: var(--prosouls-white);
  font-family: 'Avenir';
  text-align: justify;
  margin: 0;
}
@media screen and (max-width: 991.98px) {
  .testimonial-content {
    background-size: 40px;
}
.testimonial-content {
  padding: 16px;
  height: max-content;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAvCAYAAAC8CadvAAAABHNCSVQICAgIfAhkiAAAAlxJREFUaIHtmtGR2jAURa+Z/Gc7CB2EDuJUEDpYSthUkE0FlLBJBWwqgK2ApAJvB9DByYfwQFgbkCW954/cT9kz9/iOLD3rWfovVdaGQC2plvSpqqrP1v5dHFaGC2AF7DiRibknBzADns4NrUNw4QBqYN1naBWCC0eMackQXDiAaaxpiRBcOIA74HGIae4QXDgIi802xThHCG4cwEOq6WDzfzkWLhyErSabEgLIyvHuRtM7SWtJs6HgOXTgWClUetl0NYSRBVCE42IIBY1fJW1uvdmVg1Bn51JDWFSnsaTkXQMawpZ6PVAS994TrQlfbIPkxkEoPVPVRJmOiYNQCTaJxo8pD+/OASwTTHfAPDWAA0fKaxDFMTkznkp6SGDfSNoTVvPBOnB8c+Eg7yq8BRZDnsCNg/AOllBDxNQ8cPSeAhXlIOMHSY+ebgzBj4OBBxLZAI4cOQu0OA4D41bLKyH4cJCnKIlR3ROAC0e7RVp/IfZtf9YcS+kYQtK+PkB1z2yw5pgBdRvCB2NzSfrSMfbenEK6b0OYOpjXHWMeBzf15Po9xdT1wHtzCmnahvDiYN6lPx6mnjNhNGpD+O1KcdTGw3RsIbhwTCSpqqpXB4A3flVV7T04TteEn9bmPePmHKchPBub/+oZ/2EJoXMOMvf4Lqi5RGXNcb5FfpVNwfL9ynUrju5Xj/InO9tb6Nw5KDcdd9zS/hoLRyGA6J5EIY6FB8COtH5kTo745hAwJ+0IfM2ATvToODj+qdZEmK5IbMhacUT/4E1YUOaSPurtcdiLwo8Pz4cSuJhycvwFGnKFZCc50EgAAAAASUVORK5CYII=);
  background-position: 20px 20px;
  background-repeat: no-repeat;
  background-size: 40px;
  padding: 60px 0 0 0;
}
}