:root {
  --prosouls-blue: #29abe2 ;
  --prosouls-white: #ffffff;
  --prosouls-black: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif , "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 15%;
}


@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/Metropolis-Light.otf) format('truetype');
  font-display: swap;
}
#lite-badge{
  display: none !important;
}