.application-modernization-expertise-banner {
  background: url(../../../assets/application-modernization/application-modernization.png);
  background-position: 80% bottom;
  background-repeat: no-repeat;
  background-size: 45%;
}
.clr-text-white {
  color: var(--prosouls-white);
  font-family: "Avenir";
}
.application-modernization-expertise-first-module {
  padding: 20% 0 15% 0;
  width: 35%;
  text-align: left;
  /* margin: 0 0 0 12.1%; */
}
.application-modernization-expertise-first-module-btn-parent {
  margin: 20px 0;
}
.application-modernization-expertise-first-module-btn {
  transition: all ease-in 0.1s;
  border: 2px solid var(--prosouls-blue);
  color: var(--prosouls-blue);
  text-transform: uppercase;
  padding: 10px 10px 8px 10px;
  width: max-content;
  text-decoration: none;
  font-family: "Avenir";
  display: flex;
  align-items: center;
}
.application-modernization-expertise-first-module-btn-icon {
  margin: 0 15px 0 5px;
}
.application-modernization-expertise-first-module-btn:hover {
  color: var(--prosouls-blue);
  font-family: "Avenir";
}
.application-modernization-expertise-first-module-btn:hover
  .application-modernization-expertise-first-module-btn-icon {
  margin: 0 5px 0 15px;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}
.application-modernization-expertise-second-module-flex {
  display: flex;
  /* margin: 5% 0; */
  width: 100%;
  overflow: hidden;
}
.application-modernization-expertise-second-module-column.one {
  /* display: table-cell; */
  /* padding: 16px; */
  width: 40%;
}
.application-modernization-expertise-second-module-parent .container {
  max-width: 1920px;
  padding: 0;
}
.application-modernization-expertise-second-module-column.solid-bg {
  /* background-image: linear-gradient(to right, #29abe2, #00ffff); */
  display: flex;
  align-items: center;
  width: 65%;
  border: 2px solid white;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  background-color: #021928;
  border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.application-modernization-stroke-font {
  font-size: 140px;
  font-family: "Avenir";
  text-transform: uppercase;
  line-height: 0.9;
  margin-left: 0px;
  text-align: start;
  letter-spacing: 25px;
  color: var(--prosouls-white);
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}
.application-modernization-expertise-number-flex {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  column-gap: 10%;
  align-items: center;
}
.application-modernization-expertise-number-column {
  /* background-image: linear-gradient(to right, #29abe2, #00ffff); */
  padding: 20px 50px;
  width: 35%;
  border: 2px solid white;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  background-color: #021928;
  border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.application-modernization-expertise-number-column h1 {
  font-size: 50px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 900;
  margin: 0;
}
.application-modernization-expertise-number-column p {
  font-size: 20px;
  text-align: center;
  margin: 0;
  font-family: "Avenir";
}
.application-modernization-solid-bg-ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  /* list-style: none; */
}
.application-modernization-solid-bg-list-items {
  text-align: start;
  color: var(--prosouls-white);
}
.application-modernization-solid-bg-list-items p {
  font-size: 20px;
}
.application-modernization-expertise-solid-bg-content {
  width: 70%;
  margin: 0 auto 0 60px;
}
.application-modernization-expertise-solid-bg-content h1,
h2,
h3 {
  text-align: start;
  margin-bottom: 20px;
}
.application-modernization-expertise-third-module-parent .container {
  display: flex;
  column-gap: 150px;
  align-items: flex-start;
}
.application-modernization-expertise-third-module-details {
  margin: 40px 0px;
  padding: 20px;
  text-align: start;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  /* background: linear-gradient(139deg , transparent, var(--prosouls-blue)36); */
}
.application-modernization-expertise-third-module-parent .sub-title {
  font-size: 50px;
}
.application-modernization-expertise-third-module-parent .main-title {
  font-size: 70px;
}
.clr-text-blue {
  color: var(--prosouls-blue);
  font-weight: 400;
  font-family: "Avenir";
}
/* .application-modernization-expertise-third-module-parent{
    background: url(../../../assets/application-modernizationbuiding.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95%;
    padding: 100px 0;
} */
.application-modernization-expertise-third-module {
  /* padding: 10% 0 10% 0;     */
  text-align: start;
  width: 50%;
  margin-bottom: 100px;
}
.application-modernization-expertise-third-module-icon {
  font-size: 50px !important;
  color: var(--prosouls-blue);
}
.application-modernization-expertise-fourth-bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.application-modernization-expertise-underline {
  height: 5px;
  width: 20%;
  background: var(--prosouls-blue);
  border-radius: 10px;
}
.application-modernization-expertise-sixth-module-parent {
  margin: 100px 0;
}
.application-modernization-expertise-sixth-module-parent h1 {
  width: 60%;
  text-align: start;
}
.application-modernization-expertise-get-qoute,
.application-modernization-expertise-get-qoute:hover {
  padding: 10px 25px;
  background-color: var(--prosouls-blue);
  border-radius: 5px;
  text-transform: uppercase;
  color: var(--prosouls-white);
  text-decoration: none;
}
.get-qoute-parent-application-modernization-expertise {
  text-align: start;
  margin: 50px 0;
}
.application-modernization-table-section {
  width: 100%;
}
.application-modernization-table {
  width: 100%;
}
.application-modernization-table-head {
  background-image: linear-gradient(to right, #29abe2, #00ffff);
  text-align: center;
  padding: 20px;
}
.application-modernization-table-head h1 {
  text-align: center;
  font-size: 60px;
  margin: 0;
  font-weight: 700;
}
.application-modernization-table tbody tr td {
  width: 33%;
  border: 2px solid white;
  padding: 20px 40px;
  vertical-align: baseline;
}
.application-modernization-table tbody tr td p {
  text-align: start;
  /* margin: 0; */
  /* font-size: 20px; */
  font-weight: 500;
}
.application-modernization-table tbody tr td h3 {
  text-align: start;
  font-size: 30px;
  color: var(--prosouls-white);
  text-transform: uppercase;
}
.application-modernization-table tbody tr td h4 {
  text-align: start;
  font-size: 25px;
  color: var(--prosouls-white);
}
.clr-txt-blue {
  color: var(--prosouls-blue);
}
.application-modernization-expertise-fourth-module-parent {
  margin: 5% 0;
}
.application-modernization-content {
  font-size: 25px;
}
.application-modernization-table-img {
  object-fit: contain;
  width: 100%;
  height: auto;
  vertical-align: text-top;
}
.application-modernization-third-module-section {
  /* border-top: 2px solid white; */
  /* border-bottom: 2px solid white; */
  display: table;
  width: 100%;
  /* border-bottom: 2px solid white; */
}
.application-modernization-third-module-section__item.column-one {
  /* border-right: 2px solid white; */
  display: table-cell;
  padding: 40px 30px 40px 30px;
  width: 35%;
  /* background-image: linear-gradient(to right, #29abe2, #00ffff); */
  border: 2px solid white;
  border-width: 1px !important;
  border-image-slice: 1 !important;
  /* border: none; */
  border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.application-modernization-third-module-section__item.column-two {
  display: table-cell;
  width: 65%;
  padding: 40px;
}
.application-modernization-fourth-module-section {
  display: table;
  width: 100%;
  /* border-bottom: 2px solid white; */
  /* margin: 2rem auto; */
}
.application-modernization-fourth-module-section__item {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
}
.application-modernization-fourth-module-section__item.column-one {
  /* border-right: 2px solid white; */
  padding: 20px 30px 40px 0px;
}
.application-modernization-fourth-module-section__item.column-two {
  padding: 20px 0px 40px 30px;
}
.application-modernization-fourth-inner-module-section {
  display: table;
  /* border-bottom: 2px solid white; */
  /* margin: 2rem auto; */
}
.application-modernization-fourth-inner-module-section__item {
  width: 50%;
  display: table-cell;
}
.application-modernization-fourth-inner-module-section__item.column-one {
  /* border-right: 2px solid white; */
  padding: 10px 20px 10px 0;
}
.application-modernization-fourth-inner-module-section__item.column-two {
  padding: 10px 20px;
}

.eight h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 70px;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 70px;
  grid-gap: 30px;
  align-items: center;
  align-content: center;
}

.eight h1:after,
.eight h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
}
.mng-line-height {
  line-height: 2;
}
.application-modernization-fifth-module-section {
  display: table;
  /* border-bottom: 2px solid white; */
}
.application-modernization-fifth-module-section__item {
  width: 50%;
  display: table-cell;
}
.application-modernization-fifth-module-section__item.column-one {
  border-right: 2px solid white;
  padding: 20px 30px 20px 0px;
}
.application-modernization-fifth-module-section__item.column-two {
  padding: 20px 0px 20px 30px;
}
.service-cataslog-ul {
  padding: 0;
  color: var(--prosouls-white);
}
.service-cataslog-ul > li {
  padding: 7.5px 0;
  color: var(--prosouls-white);
}
.application-modernization-microsoft-partner {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.application-modernization-btn-ancher,
.application-modernization-btn-ancher:hover {
  color: var(--prosouls-white);
  text-decoration: none;
}
.application-moderniation-tech-flex {
  display: flex;
}
.application-moderniation-tech-column {
  width: 20%;
  border: 1px solid white;
  padding: 20px;
}
.application-moderniation-tech-column:not(:last-child) {
  border-right: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
  .application-modernization-expertise-banner {
    background-size: 100%;
    background-position: center 110px;
  }
  .application-modernization-expertise-first-module {
    padding: 120% 0 15% 0;
    width: 100%;
    text-align: center;
  }
  .application-modernization-expertise-second-module-flex {
    display: grid;
}
.application-modernization-stroke-font {
    font-size: 40px;
    text-align: center;
    letter-spacing: 0px;
}
.application-modernization-expertise-second-module-column.one {
    width: 100%;
}
.application-modernization-expertise-solid-bg-content {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
.application-modernization-expertise-second-module-column.solid-bg {
    width: 100%;
}
.application-modernization-third-module-section {
    border-top: none;
    border-bottom: none;
    display: grid;
    width: 100%;
}
.application-modernization-third-module-section__item.column-one {
    padding: 20px;
    width: 100%;
    order: 2;
}
.application-modernization-third-module-section__item.column-two {
    width: 100%;
    padding: 20px;
}
.application-modernization-fourth-module-section {
    display: grid;
    width: 100%;
}
.application-modernization-fourth-module-section__item.column-one {
    padding: 20px;
    width: 100%;
    order: 2;
}
.application-modernization-fourth-module-section__item {
    width: 100%;
    vertical-align: middle;
}
.application-moderniation-tech-flex {
    display: grid;
    margin-bottom: 10%;
}
.application-moderniation-tech-column {
    width: 100%;
    border: 1px solid white ;
    border-right: 1px solid white !important;
    padding: 20px;
}
  .application-modernization-expertise-second-module {
    padding: 80% 0 10% 0;
    width: 90%;
    text-align: center;
    margin: 0 auto 0 auto;
  }
  .application-modernization-expertise-second-module-parent {
    background-position: center top;
  }
  .md-none {
    display: none;
  }
  .application-modernization-expertise-third-module-parent .container {
    display: contents;
  }
  .application-modernization-expertise-third-module {
    width: 87%;
    margin: 0 auto;
  }
  .application-modernization-expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
  .application-modernization-expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
  }
  .application-modernization-expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg, transparent, var(--prosouls-blue)36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
  }
  .application-modernization-expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
  }
}
