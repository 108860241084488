.light-box-video-parent {
  z-index: 99999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #001a27f2;
  /* opacity: 0.9; */
}
.light-box-video {
  margin: 0 auto;
}
.light-box-close-btn {
  position: fixed;
  top: 30px;
  right: 15px;
  background-color: #001a27cc;
  color: var(--prosouls-white);
  border: none;
  box-shadow: 0 0 10px var(--prosouls-blue);
  border-radius: 100px;
  width: 35px;
  height: 35px;
  padding: 0;
  transition: all ease-in-out 0.3s;
}
.light-box-video-parent div {
  margin-top: 5%;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  width: 70% !important;
  height: 80% !important;
}
.light-box-close-btn:hover {
  background-color: var(--prosouls-blue);
  color: black;
  box-shadow: 0 0 25px var(--prosouls-blue);
}
@media screen and (max-width: 992px) {
  .light-box-video-parent div {
    margin: auto auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .light-box-video-parent div video {
    height: auto !important;
  }
}
