@media screen and (max-width: 900px) {
  .center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 90vw;
    width: 100vw;
    padding-top: 10%;
  }
  .homelogo {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25vw;
    height: 35vw;
    margin-top: 15%;
  }

  .Heading {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: 30px;
    font-family: Avenir;
    letter-spacing: 5px;
    color: var(--prosouls-white);
  }

  .text {
    text-align: center;
    margin-top: 15px;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 14px;
    font-family: Avenir;
    color: var(--prosouls-white);
  }

  .btn23 {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    color: var(--prosouls-white);
    background-color: aliceblue;
    font-size: 30px;
    border-radius: 10px;
  }

  .btn:hover {
    background-color: lightblue;
    color: aliceblue;
  }

  .Home1 {
    background-color: #021928;
  }
}

:root {
  --glow-color: var(--prosouls-blue);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.home-glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 10px 20px;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 900;
  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 5s linear infinite;
}

.glowing-txt {
  /* float: left; */
  /* margin-right: -0.8em; */
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 8s linear infinite;
}

.home-faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 4s linear infinite;
}

.home-glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.home-glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 500ms linear;
}

.home-glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.home-glowing-btn:hover .glowing-txt {
  animation: none;
}

.home-glowing-btn:hover .home-faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.home-glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.home-glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .home-glowing-btn {
    font-size: 1em;
  }
}

@media screen and (min-width: 901px) {
  .center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 40%;
    width: 65%;
  }
  .homelogo {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 15%;
  }

  .Heading {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: 40px;
    font-family: Avenir;
    letter-spacing: 15px;
    color: var(--prosouls-white);
  }

  .text {
    text-align: center;
    margin-top: 15px;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
    font-family: Avenir;
    color: var(--prosouls-white);
  }

  .btn {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    min-width: 10%;
    color: var(--prosouls-white);
    background-color: aliceblue;
    font-size: 30px;
    border-radius: 10px;
    margin-bottom: 10%;
  }

  .btn:hover {
    background-color: lightblue;
    color: aliceblue;
  }
}
 .banner-image-parent img{
  /* position: relative; */
  mix-blend-mode: lighten;
}
/*.banner-home-animated-content-parent {
  position: absolute;
  overflow: hidden;
  height: 70%;
  width: 100%;
  top: 20%;
  display: flex;
  column-gap: 10%;
  align-items: center;
   align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor:default;
}
.banner-home-animated-content {
  width: 33%;
   margin-bottom: 20px;
}
.banner-home-animated-content h3.right {
  text-align: left;
  color: var(--prosouls-blue);
  font-size: 30px;
  text-shadow: 0px 0px 10px var(--prosouls-blue);
  font-family: 'Avenir';
  color: var(--prosouls-blue);
  -webkit-text-stroke: 1px var(--prosouls-blue);
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  animation: transformRight ease-in-out 5s infinite;
}
.banner-home-animated-content h3.left {
  text-align: right;
  color: var(--prosouls-blue);
  font-size: 30px;
  text-shadow: 0px 0px 10px var(--prosouls-blue);
  font-family: 'Avenir';
  color: var(--prosouls-blue); 
  -webkit-text-stroke: 1px var(--prosouls-blue);
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  animation: transformLeft ease-in-out 5s infinite;
}
#slideset1 > * {
  opacity: 0;
  animation: ease-in-out 30s autoplay1 infinite;
}
 .third-child{
  width: 100%;
}
@keyframes autoplay1 {
  0%{
    opacity: 0;
  }
  5%{
    opacity: 0;
  }
  8%{
    opacity: 1;
  }
  11%{
    opacity: 0
   }
  12%{
    opacity: 0;
  }
}
#slideset1 > .third-child {
  animation-delay: 0s;
}
#slideset1 > .fifth-child {
  animation-delay: 5s;
}
#slideset1 > .fourth-child {
  animation-delay: 10s;
}
#slideset1 > .second-child {
  animation-delay: 15s;
}
#slideset1 > .sixth-child {
  animation-delay: 20s;
}
#slideset1 > .first-child {
  animation-delay: 25s;
}
@keyframes transformRight {
  0% {
    opacity: 0;
    transform: translate(0, 0);    
  }
  10% {
    opacity: 0.1;
    transform: translate(0, 0);     
  }
  20% {
    opacity: 0.3;     
  }
  30% {
    opacity: 0.6;
   transform: translate(0, 0);     
  }
  40% {
    opacity: 0.9;
   transform: translate(0, 0);     
  }
  50% {
    opacity: 1;
   transform: translate(0, 0);     
  }
  60% {
    opacity: 0.9;
   transform: translate(0, 0);     
  }
  
  70% {
   transform: translate(200px,0px); 
    opacity: 0.6;
  }
  80% {
   transform: translate(200px,0px); 
    opacity: 0.3;
  }
  100% {
    transform: translate(500px,0px);
    opacity: 0;
  }
}
@keyframes transformLeft {
  0% {
    opacity: 0;
    transform: translate(500, 0);    
  }
  10% {
    opacity: 0.1;
   transform: translate(0, 0);     
  }
  20% {
    opacity: 0.3;
   transform: translate(0, 0);     
  }
  30% {
    opacity: 0.6;
   transform: translate(0, 0);     
  }
  40% {
    opacity: 0.9;
   transform: translate(0, 0);     
  }
  50% {
    opacity: 1;
   transform: translate(0, 0);     
  }
  60% {
    opacity: 0.9;
   transform: translate(0, 0);     
  }
  
  70% {
   transform: translate(200px,0px); 
    opacity: 0.6;
  }
  80% {
   transform: translate(200px,0px); 
    opacity: 0.3;
  }
  100% {
    transform: translate(-500px,0px);
    opacity: 0;
  }
} */
/* .banner-home-animated-content h3 span {
  overflow: hidden;
  background: linear-gradient(90deg, var(--prosouls-blue), var(--prosouls-blue), var(--prosouls-blue));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 4s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
} */

/* @keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
} */
