.products-expertise-banner {
  background: url(../../../assets/products/products.png);
  background-position: 90% center;
  background-repeat: no-repeat;
  background-size: 50%;
}
.clr-text-white {
  color: var(--prosouls-white);
  font-family: "Avenir";
}
.products-expertise-first-module {
  padding: 15% 0 15% 0;
  width: 35%;
  text-align: left;
  /* margin: 0 0 0 12.1%; */
}
.products-expertise-first-module-btn-parent {
  margin: 20px 0;
}
.products-expertise-first-module-btn {
  transition: all ease-in 0.1s;
  border: 2px solid var(--prosouls-blue);
  color: var(--prosouls-blue);
  text-transform: uppercase;
  padding: 10px 10px 8px 10px;
  width: max-content;
  text-decoration: none;
  font-family: "Avenir";
  display: flex;
  align-items: center;
}
.products-expertise-first-module-btn-icon {
  margin: 0 15px 0 5px;
}
.products-expertise-first-module-btn:hover {
  color: var(--prosouls-blue);
  font-family: "Avenir";
}
.products-expertise-first-module-btn:hover
  .products-expertise-first-module-btn-icon {
  margin: 0 5px 0 15px;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}
.products-expertise-second-module-flex {
  display: flex;
  /* margin: 5% 0; */
  width: 100%;
  overflow: hidden;
}
.products-expertise-second-module-column.one {
  /* display: table-cell; */
  /* padding: 16px; */
  width: 40%;
}
.products-expertise-second-module-parent .container {
  max-width: 1920px;
  padding: 0;
}
.products-expertise-second-module-column.solid-bg {
  /* background-image: linear-gradient(to right, #29abe2, #00ffff); */
  display: flex;
  align-items: center;
  width: 65%;
  border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.products-stroke-font {
  font-size: 140px;
  font-family: "Avenir";
  text-transform: uppercase;
  line-height: 0.9;
  margin-left: 0px;
  text-align: start;
  letter-spacing: 25px;
  color: var(--prosouls-white);
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}
.products-expertise-number-flex {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  column-gap: 10%;
  align-items: center;
}
.products-expertise-number-column {
  /* background-image: linear-gradient(to right, #29abe2, #00ffff); */
  padding: 20px 50px;
  width: 35%;
  border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    background-color: #021928;
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.products-expertise-number-column h1 {
  font-size: 50px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 900;
  margin: 0;
}
.products-expertise-number-column p {
  font-size: 20px;
  text-align: center;
  margin: 0;
  font-family: "Avenir";
}
.products-solid-bg-ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  /* list-style: none; */
}
.products-solid-bg-list-items {
  text-align: start;
  color: var(--prosouls-white);
}
.products-solid-bg-list-items p {
  font-size: 20px;
}
.products-expertise-solid-bg-content {
  width: 70%;
  margin: 0 auto 0 60px;
}
.products-expertise-solid-bg-content h1,
h2,
h3 {
  text-align: start;
  margin-bottom: 20px;
}
.products-content {
  font-size: 25px;
}
.clr-txt-blue {
  color: var(--prosouls-blue);
}
.products-expertise-cards-flex {
  margin: 7.5% 0;
  display: table;
  border-spacing: 25px;
}
.products-expertise-cards-column {
  border: 1px solid white;
  position: relative;
  display: table-cell;
  width: 30%;
  padding: 30px;
}
.products-expertise-card-icon {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  height: 100px;
  width: 100px;
  border: 1px solid white;
  border-radius: 100px;
  box-shadow: 0px 0px 60px var(--prosouls-blue);
  background: #021928;
}
.products-expertise-card-icon-img {
  padding: 20px;
  width: 100%;
}
.products-expertise-card-title .clr-txt {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  margin: 40px auto 20px;
}
.products-expertise-card-btn {
  text-align: start;
  margin: 20px 0;
}
.products-expertise-card-ancher,
.products-expertise-card-ancher:hover {
  color: var(--prosouls-white);
  text-decoration: none;
}
.products-expertise-card-brochure-btn {
  border: 1px solid white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -17px;
  margin: 0 auto;
  background: #021928;
  /* padding: 10px; */
  width: 60%;
}
.products-expertise-card-ancher-brochure,
.products-expertise-card-ancher-brochure:hover {
  background-color: #021928;
  color: var(--prosouls-white);
  /* padding: 10px;    */
  /* font-size: 20px; */
  text-decoration: none;
  width: 100%;
  line-height: 2;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {
  .products-expertise-banner {
    background-size: 90%;
    background-position: center 120px;
  }
  .products-expertise-first-module {
    padding: 110% 0 15% 0px;
    width: 100%;
    text-align: center;
  }
  .products-expertise-second-module-flex {
    display: grid;
    width: 100%;
    overflow: hidden;
  }
  .products-expertise-second-module-column.one {
    width: 100%;
  }
  .products-stroke-font {
    font-size: 40px;
    text-align: center;
    letter-spacing: 3px;
  }
  .products-expertise-second-module-column.solid-bg {
    width: 100%;
  }
  .products-expertise-solid-bg-content {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  .products-expertise-cards-flex {
    margin: 7.5% 0;
    display: grid;
  }
  .products-expertise-cards-column {
    width: 100%;
    padding: 20px;
    margin: 50px auto;
  }
  .products-expertise-third-module-parent .container {
    display: contents;
  }
  .products-expertise-third-module {
    width: 87%;
    margin: 0 auto;
  }
  .products-expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
  .products-expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
  }
  .products-expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg, transparent, var(--prosouls-blue)36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
  }
  .products-expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
  }
}
