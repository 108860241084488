/* @media screen and (min-width: 901px) {
  }
  
  @media screen and (max-width: 900px) {
  } */
  .case-study-banner{
    background: url(../../assets/case-study-banner.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .case-study-banner-container{
    min-height: 500px;
    display: flex;
    align-items: flex-end;
  }
  .case-study-banner-content{
    padding-bottom: 60px;
    color: #fff;
    width: 80%;
  }
  .clr-txt{
    color: var(--prosouls-white);
    font-family: 'Avenir';
  }
  .case-study-content-subtitle{
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 1rem;
  }
  .case-study-content-title{
    margin-bottom:0;
  }
  .case-study-module-one{
    margin: 5% auto;
  }
  .case-study-module-one-column.column-one{
    width: 57.5%;
  }
  .case-study-module-one-column.column-one {

  }
  .case-study-module-one-column.column-two{
    width: 37.5%;
  }
  .case-study-module-one-flex{
    display: flex;
    column-gap: 5%;
    justify-content: center;
    align-items: flex-start;
  }
  .case-study-desciption p , .case-study-desciption span , .case-study-desciption h1 , .case-study-desciption h2 ,.case-study-desciption h3 , .case-study-desciption h4 ,.case-study-desciption h5 ,.case-study-desciption h6{ 
    line-height: 1.1 !important;
    color: var(--prosouls-white);
    text-align: start;
  }
  .case-study-desciption a{
    color: var(--prosouls-blue);
    text-decoration: none;
  }
  .case-study-desciption a{
    color: var(--prosouls-blue);
  }
  .case-study-form-title h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 18px;
    grid-gap: 15px;
    align-items: center;
    align-content: center;
}
.case-study-form-title h1:after, .case-study-form-title h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
}
.case-study-form-title h1:after, .case-study-form-title h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
}
.login-box {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  width: 100%;
  padding: 40px 40px 20px 40px;
  /* transform: translate(-50%, -50%); */
  /* background: rgba(0,0,0,.5); */
  box-sizing: border-box;
  /* box-shadow: 0 15px 25px rgba(0,0,0,.6); */
  box-shadow: inset 0px 0px 10px 0px var(--prosouls-blue);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: var(--prosouls-blue);
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: var(--prosouls-blue);
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  /* margin-top: 40px; */
  letter-spacing: 4px
}

.login-box a:hover {
  background: var(--prosouls-blue);
  color: black;
  border-radius: 5px;
  box-shadow: 0 0 5px var(--prosouls-blue),
              0 0 25px var(--prosouls-blue),
              0 0 50px var(--prosouls-blue),
              0 0 100px var(--prosouls-blue);
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--prosouls-blue));
  animation: btn-anim1 2s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--prosouls-blue));
  animation: btn-anim2 2s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--prosouls-blue));
  animation: btn-anim3 2s linear infinite;
  animation-delay: 1s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--prosouls-blue));
  animation: btn-anim4 2s linear infinite;
  animation-delay: 1.5s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
