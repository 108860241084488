.expertise-banner{
    background: url(../../../assets/application-integration/2.png);
    background-position: 80% 100px;
    background-repeat: no-repeat;
    position: relative;
    background-size: 29%;
}
.clr-text-white{
    color: var(--prosouls-white);
    font-family: 'Avenir';
}
.expertise-first-module {
    padding: 15% 0 10% 0;
    width: 35%;
    text-align: left;
    /* margin: 0 0 0 12.1%; */
}
.expertise-first-module-btn-parent{
    margin: 20px 0;
}
.expertise-first-module-btn{
    transition: all ease-in 0.1s;
    border: 2px solid var(--prosouls-blue);
    color: var(--prosouls-blue);
    text-transform: uppercase;
    padding: 10px 10px 8px 10px;
    width: max-content;
    text-decoration: none;
    font-family: 'Avenir';
    display: flex;
    align-items: center;
    border: 2px solid white;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    /* border: none; */
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.expertise-first-module-btn-icon{
    margin: 0 15px 0 5px;
}
.expertise-first-module-btn:hover {
    color: var(--prosouls-blue) ;
    font-family: 'Avenir';
}
.expertise-first-module-btn:hover .expertise-first-module-btn-icon{
    margin: 0 5px 0 15px;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}
/* .expertise-second-module-parent{
    margin-top: 50px;
    background: url(../../../assets/application-second-module-bg.png);
    background-position: 15% top;
    background-repeat: no-repeat;
    background-size: contain;
    mix-blend-mode: lighten;
}
.expertise-second-module {
    padding: 10% 0 10% 0;
    width: 50%;
    text-align: left;
    margin: 0 0 0 auto;
} */
.expertise-third-module-parent{
    margin: 5% auto 0;
}
.expertise-third-module-parent .container {
    display: flex;
    column-gap: 150px;
    align-items: flex-start;

}
.expertise-third-module-details{
    margin: 40px 0px;
    padding: 20px;
    text-align: start;
        display: flex;
    column-gap: 25px;   
    align-items: flex-start;
    /* background: linear-gradient(139deg , transparent, var(--prosouls-blue)36); */
}
.clr-text-blue{
    color: var(--prosouls-blue);
    font-weight: 400;
    font-family: 'Avenir';
}
.expertise-third-module {
    /* padding: 10% 0 10% 0;     */
    text-align: center;
    width: 100%;
}
.expertise-third-module-icon{
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
.expertise-fourth-bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.expertise-underline{
    height: 5px;
    width: 20%;
    background: var(--prosouls-blue);
    border-radius: 10px;
}
.usp-card-parent{
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    /* column-gap: 40px; */
}
.usp-card h3 , .usp-card p {
    text-align: center;
}
.usp-card {
    /* border: 2px solid var(--prosouls-blue); */
    padding: 20px;
    text-align: center;
    width: 30%;
    border: 1px solid;
    border-width: 1px !important;
    border-image-slice: 1 !important;
    /* border: none; */
    border-image-source: linear-gradient(to right, #29ABE2 , #00FFFF) !important;
}
.expertise-sixth-module-parent{
    margin: 100px 0;
}
.expertise-sixth-module-parent h1{
    width: 60%;
    text-align: start;
}
.expertise-get-qoute , .expertise-get-qoute:hover{
    padding: 10px 25px;
    background-color: var(--prosouls-blue);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--prosouls-white);
    text-decoration: none;
}
.get-qoute-parent-expertise{
    text-align: start;
    margin: 50px 0;
}
.application-integration-second-module-section {
    position: relative;
    display: table;
    width: 100%;
    /* border-bottom: 2px solid white; */
}
.application-integration-second-module-section__item.column-one {
    /* border-right: 2px solid white; */
    display: table-cell;
    /* padding: 40px 30px 40px 30px; */
    width: 50%;
}
.application-integration-second-module-section__item.column-two {
    display: table-cell;
    width: 50%;
    padding: 40px;
    vertical-align: top;
}
.application-integration-section {
    width: 100%;
}
.application-integration-fourth-module-parent {
    margin: 5% 0;
}
.application-integration tbody tr td {
    width: 33%;
    border: 1px solid white;
    padding: 20px 40px;
    vertical-align: baseline;
}
.application-integration tbody tr td h3 {
    text-align: start;
    font-size: 30px;
    color: var(--prosouls-blue);
    text-transform: uppercase;
}
.application-integration tbody tr td p {
    text-align: start;
    font-weight: 500;
}
.application-integration-table-heading h1{
    text-transform: uppercase;
    margin: 15px auto;
    font-weight: 500;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 992px) {

    .expertise-banner {
        background-position: center 110px;
        background-size: 75%;
    }
    .expertise-first-module {
    padding: 100% 0 10% 0;
    width: 100%;
    text-align: center;
}
.expertise-first-module-btn {
    margin: 0 auto;
}
.expertise-second-module {
    padding: 80% 0 10% 0;
    width: 90%;
    text-align: center;
    margin: 0 auto 0 auto;
}
.application-integration-second-module-section {
    position: relative;
    display: grid;
    width: 100%;
    /* border-bottom: 2px solid white; */
}
.application-integration-second-module-section__item.column-one {
    width: 100%;
}
.application-integration-second-module-section__item.column-two {
    width: 100%;
    padding: 20px;
}
.application-integration tbody tr{
    display: grid;
}
.application-integration tbody tr td {
    width: 100%;
}
.application-integration tbody tr td:first-child{
    border-bottom: none;
}
.application-integration tbody tr:last-child td:first-child{
    border-top:none;
}
.expertise-second-module-parent {
    background-position: center top;
}
.md-none{
    display: none;
}
.usp-card {
    text-align: center !important;
    width: 100%;
}
.usp-card-parent {
    margin: 50px 0;
    display: contents;
}
.expertise-third-module-parent .container {
    display: contents;
}
.expertise-third-module{
    width: 87%;
    margin: 0 auto;
}
.expertise-sixth-module-parent h1 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
}
.expertise-third-module-content {
    padding: 20px 0 10px;
    text-align: center;
}
.expertise-third-module-details {
    margin: 40px 0px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: linear-gradient;
    background: linear-gradient(139deg , transparent, #61efff36);
    align-items: flex-start;
    /* border: 2px solid var(--prosouls-blue); */
}
.expertise-third-module-icon {
    font-size: 50px !important;
    color: var(--prosouls-blue);
}
}